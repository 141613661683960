import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, lastValueFrom, map, take } from 'rxjs';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { CameraRowComponent } from '../camera-row/camera-row.component';
import { Dictionary } from '@ngrx/entity/src/models';
import { SelectedCamera } from '@models/alert-events.model';
import { Edge } from '../../../../edge/edge.model';
import EdgeItem = Edge.EdgeItem;
import { LocationModel } from '../../../../locations/location.model';
import { HomeModel } from '@models/home.model';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { HomeSelectors } from '@states/home/home.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PipesModule } from '../../../../pipes/pipes.module';

@UntilDestroy()
@Component({
  selector: 'camera-selector-sublocation-row',
  standalone: true,
  imports: [
    MatCheckbox,
    AsyncPipe,
    NgIf,
    CameraRowComponent,
    NgForOf,
    JsonPipe,
    PipesModule,
  ],
  templateUrl: './sublocation-row.component.html',
  styleUrls: ['./sublocation-row.component.scss', './../../styles/index.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SublocationRowComponent implements OnChanges, OnInit {
  @Input() sublocation: HomeModel.Location;
  @Input() selectedCameras: Dictionary<SelectedCamera>;
  @Input() isCollapsed: boolean = false;
  @Input() isMulti: boolean = false;
  @Input() disabledCameras: Dictionary<string> = {};
  @Input() filteredCameras: LocationModel.LocationCameraItem[];
  @Input() isQuery: boolean;


  @Output() onCameraSelected: EventEmitter<SelectedCamera> = new EventEmitter<SelectedCamera>();
  @Output() onSublocationSelected: EventEmitter<{ sublocationId: string; checked: boolean }> = new EventEmitter<{ sublocationId: string; checked: boolean }>();
  public collapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public subLocations: HomeModel.Location[];

  public descCameras: HomeModel.Camera[] = [];

  constructor(private store$: Store) {
  }

  ngOnInit(): void {
    this.store$.select(HomeSelectors.selectSubLocations(this.sublocation?._id))
      .pipe(untilDestroyed(this))
      .subscribe((subLocations) => {
        this.subLocations = subLocations;
      });
    this.getDescCameras();

  }

  public async getDescCameras() {
    this.descCameras = await lastValueFrom(this.store$.select(HomeSelectors.getAllDescendantCameras(this.sublocation._id))
      .pipe(take(1)));
  }

  public get shouldDisplay() {
    if (this.isQuery) {
      const cameras = this.descCameras.filter(camera => this.filteredCameras.some(filteredCamera => filteredCamera?.edgeOnly?.cameraId === camera.cameraId));
      if (cameras.length === 0) {
        return false;
      }
    }
    return true;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['isCollapsed']) {
      this.collapsed$.next(changes['isCollapsed'].currentValue);
    }
  }

  public toggleEdge() {
    const currentValue = this.collapsed$.getValue();
    this.collapsed$.next(!currentValue);
  }

  public get cameras() {
    if (this.filteredCameras) {
      return Object.values(this.sublocation.cameras ?? {})
        .filter(camera => this.filteredCameras.some(filteredCamera => filteredCamera?.edgeOnly?.cameraId === camera.cameraId));
    }
    return Object.values(this.sublocation.cameras ?? {});
  }

  public selectCamera(camera: SelectedCamera) {
    this.onCameraSelected.emit(camera);
  }

  public selectSublocation(ev: MatCheckboxChange) {
    this.onSublocationSelected.emit({ sublocationId: this.sublocation._id, checked: ev.checked });
  }

  public selectNestedSublocation(value: { sublocationId: string, checked: boolean }) {
    this.onSublocationSelected.emit({ sublocationId: value.sublocationId, checked: value.checked });
  }


  public get selected() {
    const selectedCameras = Object.keys(this.selectedCameras);
    return this.cameras
      .every(camera => selectedCameras.includes(camera.cameraId));
  }

  public trackBy(index: number, camera: HomeModel.Camera) {
    return camera.cameraId;
  }

  public trackBySublocation(index: number, sublocation: HomeModel.Location) {
    return sublocation._id;
  }

  getCameraById(cameraId: string) {
    return this.store$.pipe(select(CameraSelectors.selectCameraById(cameraId)))
      .pipe(
        map((res) => {
          return res as LocationModel.LocationCameraItem;
        }),
      );
  }
}
