<div class="camera-row flex-row space-between-center c-p"
     [class.disabled]="disabledCameras?.[camera.edgeOnly.cameraId]"
     (click)="selectCamera()"
>
  <div class="flex-row start-center gap-16">
    @if (isMulti) {
      <mat-checkbox type="checkbox"
                    color="primary"
                    name="select-all"
                    [checked]="selected"
      ></mat-checkbox>
    } @else {
      <mat-radio-button [checked]="selected" color="primary">

      </mat-radio-button>
    }

    <div class="flex-row start-center gap-16">
      <div class="camera-snapshot">
        <img *ngIf="({edgeId:camera.edgeId, cameraId: camera.edgeOnly.cameraId } | cameraBestImagePipe | async) && !imgLoadFailed"
             [lazyLoad]="{edgeId:camera.edgeId, cameraId: camera.edgeOnly.cameraId } | cameraBestImagePipe | async"
             (onStateChange)="imgStateChange($event)"
             #snapshot
        />
        <div *ngIf="!({edgeId:camera.edgeId, cameraId: camera.edgeOnly.cameraId } | cameraBestImagePipe | async) || !imageLoader && imgLoadFailed"
             class="flex-row center-center failed-img h-100 w-100"
        >
          <span class="ui-icon-camera-off"></span>
        </div>
      </div>
      <div class="flex-row start-center gap-4">
        <ui-camera-status [cameraId]="camera.edgeOnly.cameraId">

        </ui-camera-status>
        <div>{{ camera.edgeOnly.name }}</div>
      </div>
    </div>
  </div>

</div>
