import { createAction, props } from '@ngrx/store';
import { LocationModel } from '../../../locations/location.model';
import { GrantedAccessModel } from '@models/granted-access-model';
import { CommentRequest } from '@models/comment';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { AlertEntry } from '../../../development/alerts.service';
import { Archive } from '@models/archive.model';
import ArchiveDocument = Archive.ArchiveDocument;
import { selectInitialLoaded } from '@states/granted-access/granted-access.selectors';
import { WallV2Model } from '@models/wall-v2.model';

export const resetToInitialState = createAction('[GRANTED-ACCESS] Reset to Initial state');

export const resetSelectedGrantedAccess = createAction('[GRANTED-ACCESS] Reset Selected Granted Access');

export const checkGrantedAccess = createAction('[GRANTED-ACCESS] Check Granted Access', props<{ id: string, body?: { password: string } }>());

export const checkGrantedAccessSuccess = createAction(
  '[GRANTED-ACCESS] Check Granted Access Success',
  props<{
    // entity: GrantedAccessModel.GrantedAccessEntity,
    entityParams: GrantedAccessModel.EntityParams
  }>(),
);

export const checkGrantedAccessFail = createAction('[GRANTED-ACCESS] Check Granted Access Fail');
export const checkGrantedAccessProtected = createAction('[GRANTED-ACCESS] Check Granted Access Protected', props<{ isProtected: boolean }>());

export const getByEntityId = createAction('[GRANTED-ACCESS] Get By EntityId');

export const getByEntitySuccess = createAction('[GRANTED-ACCESS] Get By EntityId Success', props<{ grantedList: any[] }>());

export const getByEntityFail = createAction('[GRANTED-ACCESS] Get By EntityId Fail');

export const createGrantedAccess = createAction(
  '[GRANTED-ACCESS] Create Granted Access',
  props<{ grantedAccess: GrantedAccessModel.GrantedAccessModelRequest }>(),
);

export const sendCreateGrantedAccess = createAction(
  '[GRANTED-ACCESS] Send Create Granted Access',
  props<{ grantedAccess: GrantedAccessModel.GrantedAccessModelRequest }>(),
);

export const sendCreateGrantedAccessSuccess = createAction(
  '[GRANTED-ACCESS] Send Create Granted Access Success',
  props<{ url: string }>(),
);

export const setSelectedEntityId = createAction('[GRANTED-ACCESS] Set Selected Entity Id', props<{ entityId: string }>());

export const revokeAccess = createAction('[GRANTED-ACCESS] Revoke', props<{ id: string }>());
export const extendAccess = createAction('[GRANTED-ACCESS] Extend', props<{ id: string, period: number }>());
export const notifyAccess = createAction('[GRANTED-ACCESS] Notify', props<{ id: string }>());
export const viewShared = createAction('[GRANTED-ACCESS] View', props<{ id: string }>());

export const shareWith = createAction('[GRANTED-ACCESS] Share With', props<{
  id: string, users: string[], message: string
}>());

export const sendShareWith = createAction('[GRANTED-ACCESS] Send Share With', props<{
  id: string, users: string[], message: string
}>());

export const sendShareWithSuccess = createAction('[GRANTED-ACCESS] Send Share With Success');

export const sendShareWithFail = createAction('[GRANTED-ACCESS] Send Share With Fail');

export const setSelectedGrantedAccess = createAction(
  '[GRANTED-ACCESS] Set Selected Granted Access',
  props<{ selectedGrantedAccess: GrantedAccessModel.CreateGrantedAccessResponse }>(),
);

export const resetGrantedAccess = createAction('[GRANTED-ACCESS] Reset Granted Access');

export const getLocationByEdgeId = createAction('[GRANTED-ACCESS] Get Location By edge Id', props<{ edgeId: string }>());
export const getLocationByEdgeIdSuccess = createAction('[GRANTED-ACCESS] Get Location By edge Id Success', props<{ location: LocationModel.LocationItem }>());

export const getConfig = createAction('[GRANTED-ACCESS] Get Config', props<{ id: string }>());
export const getConfigSuccess = createAction('[GRANTED-ACCESS] Get Config Success', props<{ stats: GrantedAccessModel.GrantedAccessConfig }>());
export const getConfigFail = createAction('[GRANTED-ACCESS] Get Config Fail');

export const getComments = createAction('[GRANTED-ACCESS] Get Comments', props<{ id: string }>());
export const getCommentsSuccess = createAction('[GRANTED-ACCESS] Get Comments Success', props<{ comments: CommentRequest[] }>());
export const getCommentsFail = createAction('[GRANTED-ACCESS] Get Comments Fail');
export const addComment = createAction('[GRANTED-ACCESS] Add Comment', props<{ comment: CommentRequest }>());
export const sendComment = createAction('[GRANTED-ACCESS] Send Comment', props<{ comment: CommentRequest }>());
export const setSelectedType = createAction('[GRANTED-ACCESS] Set Selected Type', props<{ selectedType: GrantedAccessType }>());
export const setAuthToken = createAction('[GRANTED-ACCESS] Set Auth Token', props<{ accessToken: string }>());
export const addCommentSuccess = createAction('[GRANTED-ACCESS] Add Comment Success');
export const addCommentFail = createAction('[GRANTED-ACCESS] Add Comment Fail');

export const getPreviewUrl = createAction('[GRANTED-ACCESS] Get Preview Url', props<{ entityId: string; gType: GrantedAccessType }>());
export const getPreviewUrlSuccess = createAction('[GRANTED-ACCESS] Get Preview Url Success', props<{ url: string }>());
export const getPreviewUrlFail = createAction('[GRANTED-ACCESS] Get Preview Url Fail', props<{ entityId: string }>());

export const getGrantedAccessList = createAction('[GRANTED-ACCESS] Get Granted Access from Server');
export const setGrantedAccessList = createAction('[GRANTED-ACCESS] Set Granted Access from Server', props<{ grantedAccessList: GrantedAccessModel.GrantedAccessDocument[] }>());
export const setTotalItemsCount = createAction('[GRANTED-ACCESS] Set Total Item Counts', props<{ totalItemsCount: number }>());
export const setQuery = createAction('[GRANTED-ACCESS] Set Query', props<{ query: string }>());
/**
 * @deprecated
 */
export const setPaginationParams = createAction('[GRANTED-ACCESS] Set Pagination Params', props<{ perPage: number; page: number }>());

export const setEntityParams = createAction('[GRANTED-ACCESS] Set Entity Params', props<{ entityParams: GrantedAccessModel.EntityParams }>());

export const setSharedAlert = createAction('[GRANTED-ACCESS] Set Shared Alert', props<{ alert: AlertEntry }>());

export const getSharedArchive = createAction('[GRANTED-ACCESS] Get Shared Archive', props<{ archiveId: string }>());
export const getSharedArchiveSuccess = createAction('[GRANTED-ACCESS] Get Shared Archive Success', props<{ archive: ArchiveDocument }>());
export const getSharedArchiveFail = createAction('[GRANTED-ACCESS] Get Shared Archive Fail');
export const updateSharedArchive = createAction('[GRANTED-ACCESS] Update Shared Archive', props<{ archive: Archive.UpdateArchiveDownload }>());

export const setIsRecordRemoved = createAction('[GRANTED-ACCESS] Set Is Record Removed', props<{ isRecordRemoved: boolean }>());

export const setFilter = createAction('[GRANTED-ACCESS] Set Filter Property', props<{ property: string, value: any }>());
export const setOrderDirection = createAction('[GRANTED-ACCESS] Set Order Direction', props<{ property: string }>());
export const resetGrantedAccessList = createAction('[GRANTED-ACCESS] Reset Granted Access from Server');

export const removeFilter = createAction('[GRANTED-ACCESS] Remove Filter', props<{ property: string, value: any }>());

export const revokeAll = createAction('[GRANTED-ACCESS] Revoke All');
export const revokeAccessSuccess = createAction('[GRANTED-ACCESS] Revoke Success', props<{ grantedAccess: GrantedAccessModel.GrantedAccessDocument }>());
export const setIsLoading = createAction('[GRANTED-ACCESS] Set Is Loading', props<{ isLoading: boolean }>());
export const startLoadingGrantedAccessList = createAction('[GRANTED-ACCESS] Start Loading Granted Access from Server');
export const setInitialLoaded = createAction('[GRANTED-ACCESS] Set Initial Loaded', props<{ initialLoaded: boolean }>());
export const setSharedLiveView = createAction('[GRANTED-ACCESS] Set Shared LiveView', props<{ sharedLiveView: GrantedAccessModel.SharedLiveView }>());
export const setSharedPlayback = createAction('[GRANTED-ACCESS] Set Shared Playback', props<{ sharedPlayback: GrantedAccessModel.SharedPlayback }>());

export const getCameraRetentionDays = createAction('[GRANTED-ACCESS] Get Camera Retention Days ', props<{ edgeId: string; cameraId: string; }>());
export const getCameraOldestVideoTimestamp = createAction('[GRANTED-ACCESS] Get Camera Oldest Video Timestamp', props<{ retentionDays: number; edgeId: string; cameraId: string; }>());

export const getEdgeLastMp4 = createAction('[GRANTED-ACCESS] Get Edge Last Mp4', props<{ edgeId: string; }>());


export const getEdgeHeartBeatStatus = createAction('[GRANTED-ACCESS] Get Edge HeartBeat Status');
export const getEdgeHeartBeatStatusSuccess = createAction('[GRANTED-ACCESS] Get Edge HeartBeat Status Success', props<{ body: any[] }>());
export const getEdgeHeartBeatStatusFail = createAction('[GRANTED-ACCESS] Get Edge HeartBeat Status Fail');
