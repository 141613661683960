import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { concatMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { catchError, filter, map, of, share, switchMap, take } from 'rxjs';
import { WallV2Actions } from '@states/wall-v2/wall-v2.action-types';
import _, { isNull } from 'lodash';
import { WallV2Model } from '@models/wall-v2.model';
import { SharedActions } from '@states/shared/shared.action-types';
import { WallV2Service } from '../../services/wall-v2.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AlertEntry, AlertsService } from '../../development/alerts.service';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { AlertModel } from '@models/alert.model';
import { AlertsV2DisplaySettings, AlertsV2ShowSettings, AlertV2Document } from '@models/alerts-v2.model';
import { AlertEventsService } from '../../development/alert-events.service';
import { Dictionary } from '@ngrx/entity/src/models';
import { AudioList, audioListFiles } from '@models/shared.model';
import { getYoutubeVideoIdFromUrl, sortArrByField } from '../../helpers/common.helpers';
import { AlertMonitoringService } from '../../development/alert-monitoring.service';
import { CamerasService } from '../../cameras/cameras.service';
import { LiveStreamModels } from '@models/live-stream.model';
import moment from 'moment';
import { WebrtcService } from '../../development/webrtc.service';
import { YoutubeService } from '../../services/youtube.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LivekitService } from '../../development/livekit.service';
import WallAlert = WallV2Model.WallAlert;
import { LocationActions } from '@states/location/location.action-types';
import { LocationSelectors } from '@states/location/location.selector-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import { CameraActions } from '@states/camera/camera.action-types';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { LocationsService } from '../../locations/locations.service';
import { LocationModel } from '../../locations/location.model';
import { getWallLocations, getWallLocationsSuccess } from '@states/wall-v2/wall-v2.actions';
import { SharedApiService } from '../../services/shared-api.service';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';

@Injectable()
export class WallV2Effects {
  private delaysByAlertId: { [alertId: string]: any } = {};
  private playbackSessionsCheckTimeouts: any[] = [];
  private PLAYBACK_SESSION_CHECK_INTERVAL = 50000; //50 sec

  public setTileCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.setTileCamera),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ camera, tile }, { selectedWall, selectedSetIndex }]) => {
        const tiles = [...selectedWall.sets[selectedSetIndex].tiles];
        tiles[tile] = {
          ...WallV2Model.defaultSetTile,
          camera,
        };
        const sets = _.cloneDeep(selectedWall.sets);
        sets[selectedSetIndex] = {
          ...sets[selectedSetIndex],
          tiles,
        };
        return of(WallV2Actions.setSelectedWall({
          wall: {
            ...selectedWall,
            sets: sets,
          },
        }));
      }),
    ),
  );

  public cleanTile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.cleanSetTile),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ tile }, { selectedWall, selectedSetIndex }]) => {
        const tiles = [...selectedWall.sets[selectedSetIndex].tiles];
        tiles[tile] = WallV2Model.defaultSetTile;
        const sets = _.cloneDeep(selectedWall.sets);
        sets[selectedSetIndex] = {
          ...sets[selectedSetIndex],
          tiles,
        };
        return of(WallV2Actions.setSelectedWall({
          wall: {
            ...selectedWall,
            sets: sets,
          },
        }));
      }),
    ),
  );


  public selectLayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.selectLayout),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ layoutIndex }, { selectedWall, selectedSetIndex }]) => {
        const sets = _.cloneDeep(selectedWall.sets);

        const layoutCameras = WallV2Model.wallLayoutCameraCountV2[layoutIndex];
        const tiles: WallV2Model.SetTile[] = [];
        for(let i = 0; i < layoutCameras; i++) {
          if (!sets[selectedSetIndex].tiles[i]) {
            tiles[i] = WallV2Model.defaultSetTile;
          } else {
            tiles[i] = sets[selectedSetIndex].tiles[i];
          }
        }
        sets[selectedSetIndex] = {
          ...sets[selectedSetIndex],
          layout: layoutIndex,
          tiles: [...tiles],
        };
        return of(WallV2Actions.setSelectedWall({
          wall: {
            ...selectedWall,
            sets: sets,
          },
        }));
      }),
    ),
  );

  public dragAndDropTileEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.dragAndDropTileEvents),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ events, tile, allSelected }, { selectedWall, selectedSetIndex }]) => {
        const tiles = [...selectedWall.sets[selectedSetIndex].tiles];
        const tileEvents = tiles[tile].events ?? [];
        const existingEventsMap: Dictionary<WallV2Model.SelectedEvent> = {};
        tileEvents.forEach(tileEvent => {
          const key = `${tileEvent.cameraId}-${tileEvent.eventId}`;
          existingEventsMap[key] = tileEvent;
        });
        const updatedEvents: Dictionary<WallV2Model.SelectedEvent> = {
          ...existingEventsMap,
          ...events,
        };
        tiles[tile] = {
          ...WallV2Model.defaultSetTile,
          events: allSelected ? WallV2Model.defaultSetTile.events : Object.values(updatedEvents),
          allEvents: allSelected,
        };
        const sets = _.cloneDeep(selectedWall.sets);
        sets[selectedSetIndex] = {
          ...sets[selectedSetIndex],
          tiles,
        };
        return of(WallV2Actions.setSelectedWall({
          wall: {
            ...selectedWall,
            sets: sets,
          },
        }));
      }),
    ),
  );


  public startCreateWall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.startCreateWall),
      switchMap(({ isSave }) =>
        [
          WallV2Actions.setIsSaving({ isSaving: true }),
          !isSave ? WallV2Actions.serverRequestCreateWall() : WallV2Actions.serverRequestSaveWall(),
        ]),
    ),
  );


  public serverRequestCreateWall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.serverRequestCreateWall),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([, { selectedWall, selectedSetIndex }]) => {
          return this.wallV2Service.create({ ...selectedWall, name: !!selectedWall.name ? selectedWall.name : 'Untitled wall' })
            .pipe(
              switchMap((res) => {
                return [
                  WallV2Actions.setIsSaving({ isSaving: false }),
                  WallV2Actions.serverRequestCreateWallSuccess({ id: res._id }),
                  SharedActions.showMessage({ success: 'Wall has been created' }),
                ];
              }),
              catchError(() => {
                return [
                  WallV2Actions.setIsSaving({ isSaving: false }),
                  SharedActions.showMessage({ error: 'Creating wall failed' }),
                ];
              }),
            );
        },
      ),
      share(),
    ));

  public getWalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.getWalls),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ query }, { isLastPage, page, perPage }]) => {
          if (!isLastPage) {
            return this.wallV2Service.getAllByFilters(query, page, perPage)
              .pipe(
                switchMap((res) => {
                  return [
                    WallV2Actions.getWallsSuccess({ walls: res }),
                    WallV2Actions.setListLoader({ listLoader: false }),
                  ];
                }),
                catchError((res) => {
                  return [
                    WallV2Actions.getWallsFail(),
                    WallV2Actions.setListLoader({ listLoader: false }),
                  ];
                }),
              );
          } else {
            return of(SharedActions.doNothing());
          }

        },
      ),
      share(),
    ));


  public getWallById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.getWallById),
      switchMap(({ id }) => {
          return this.wallV2Service.getById(id)
            .pipe(
              switchMap((res) => {
                return [
                  WallV2Actions.getWallByIdSuccess({ selectedWall: res }),
                ];
              }),
              catchError((res: HttpErrorResponse) => {
                const actions: Action[] = [
                  WallV2Actions.getWallByIdFail(),
                ];
                if (res.error.statusCode === HttpStatusCode.NotFound) {
                  actions.push(WallV2Actions.getWallByIdNotFound());
                }
                return actions;
              }),
            );
        },
      ),
      share(),
    ));

  public getTableAlertsByFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.getTableAlertsByFilters),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([, { selectedWall, tableAlertsFilters, selectedSetIndex }]) => {
        const cameraEvents = [];
        let isOrganizationEvents = false;
        selectedWall.sets[selectedSetIndex].tiles.forEach(tile => {
          const events = tile?.events;
          if (events) {
            Object.values(events)
              .forEach(event => {
                const cameraEvent = {
                  eventId: event.eventId,
                  cameraId: event.cameraId,
                };
                cameraEvents.push(cameraEvent);
              });
          }
          if (tile.allEvents) {
            isOrganizationEvents = true;
          }
        });

        const filters: WallV2Model.WallAlertMonitoringFilters = { ...tableAlertsFilters, eventCameraIds: !isOrganizationEvents ? cameraEvents : [] };
        return this.alertsService.getAlertMonitoring(filters)
          .pipe(
            switchMap(alerts => {
              const alertLookup: Dictionary<WallV2Model.WallAlert> = {};

              alerts.forEach(alert => {
                const selectedAt = alert.timestamp;
                alertLookup[alert._id] = {
                  ...alert,
                  tile: null,
                  tileColor: null,
                  selectedAt,
                };
              });
              return [
                WallV2Actions.getAlertsTableSuccess({ alerts: alertLookup }),
                WallV2Actions.setAlertTableLoader({ loading: false }),
              ];
            }),
            catchError(response => {
              return [
                WallV2Actions.getTableAlertsByFiltersFail(),
                WallV2Actions.setAlertTableLoader({ loading: false }),
                this.catchError(response),
              ];
            }),
          );
      }),
    ),
  );

  public alertSocketTriggered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.alertSocketTriggered),
      withLatestFrom(
        this.store$.pipe(select(state => state.wallV2State)),
      ),
      mergeMap(([{ alerts }, { selectedWall, selectedSetIndex }]) =>
        this.livekitService.cleanupInProgress$.pipe(
          filter(value => value === false),
          take(1),
          map(() => {
            let contactedResult: AlertEntry[] = [];
            const getEventIdsForCurrentSet = this.getEventIdsForCurrentSet(selectedWall.sets[selectedSetIndex]);
            const eventIds: Dictionary<boolean> = getEventIdsForCurrentSet.eventIds;
            const isAllEvents = getEventIdsForCurrentSet.isAllEvents;

            alerts?.forEach(result => {
              let filteredByEventIdAlerts: AlertEntry[] = [];
              if (!isAllEvents) {
                filteredByEventIdAlerts = result?.result?.filter(alert => eventIds[alert.eventId]);
              } else {
                filteredByEventIdAlerts = result?.result;
              }

              if (filteredByEventIdAlerts?.length) {
                contactedResult = [...contactedResult, ...filteredByEventIdAlerts];
              }
            });

            return WallV2Actions.filterAlertsByAllowedCamerasAndEventSettings({ alerts: contactedResult });
          }),
        ),
      ),
    ),
  );


  public filterAlertsByAllowedCamerasAndEventSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.filterAlertsByAllowedCamerasAndEventSettings),
      withLatestFrom(
        this.store$.pipe(select(CameraSelectors.selectCamerasEntities)),
      ),
      switchMap(([{ alerts }, cameraEntities]) => {
        const filteredAlertsByCameraIds = alerts.filter(alert => cameraEntities[alert.cameraId]);
        const filteredByShowSettingsAlerts = AlertModel.filterAlertsByShowSettings(filteredAlertsByCameraIds, AlertsV2ShowSettings.WALLS);
        if (filteredByShowSettingsAlerts.length) {
          return of(WallV2Actions.putNewAlertsToTiles({ receivedAlerts: filteredByShowSettingsAlerts }));
        }
        return of(SharedActions.doNothing());
      }),
    ));


  public putNewAlertsToTiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.putNewAlertsToTiles),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([{ receivedAlerts }, { tableAlerts, isMuted, eventTiles, selectedWall }]) => {
        const tilesAlertsNew: { [tile: number]: AlertEntry } = {};

        let updatedTableAlerts: Dictionary<WallV2Model.WallAlert> = { ...tableAlerts };

        const sortedAlerts = sortArrByField<AlertEntry>(receivedAlerts, 'timestamp', 'desc');
        const newPlaybackStartsForAlertId: WallV2Model.WallPlaybackStartDto[] = [];
        sortedAlerts
          .forEach((alert, index) => {
            let tilesForThisAlert: number[] = eventTiles[alert.eventId] ?? [];
            tilesForThisAlert = tilesForThisAlert.concat(eventTiles['allEventsTiles']);
            if (!!tilesForThisAlert && !!tilesForThisAlert?.length) {
              const emptyTile = tilesForThisAlert.find(tile => !tilesAlertsNew[tile]);
              if (this.ifExists(emptyTile)) {
                tilesAlertsNew[emptyTile] = alert;
              }
              updatedTableAlerts = {
                ...updatedTableAlerts,
                [alert._id]: { ...alert, tile: null, tileColor: null },
              };

            }

            const track: AudioList = selectedWall.settings?.alertSettings?.audio;
            const volume = selectedWall.settings?.alertSettings?.volume;
            if (!isMuted) {
              let audio = new Audio();
              audio.src = audioListFiles[track];
              audio.volume = volume / 100;
              audio.load();
              audio.play();
            }
            const selectedAlertCamera = alert.selectedCamera;
            newPlaybackStartsForAlertId.push(
              {
                locationId: selectedAlertCamera.locationId,
                edgeId: selectedAlertCamera.edgeId,
                cameraId: selectedAlertCamera.cameraId,
                timestamp: alert.timestamp,
                alertId: alert._id,
                sessionId: null,
              },
            );

            const liveViewDuration = selectedWall.settings.alertSettings?.duration ?? WallV2Model.defaultAlertSettings.duration;
            this.delaysByAlertId[alert?._id] = setTimeout(() => {
              this.store$.dispatch(WallV2Actions.findAndCleanTileByAlertId({ alertId: alert._id }));
            }, liveViewDuration);

          });

        return [
          WallV2Actions.fillTilesByOldAlertsIfExists({ tableAlertsNew: updatedTableAlerts, tilesAlertsNew: tilesAlertsNew, newPlaybackStartsForAlertId }),
        ];
      }),
    ),
  );

  public fillTilesByOldAlertsIfExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.fillTilesByOldAlertsIfExists),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([{ tableAlertsNew, tilesAlertsNew, newPlaybackStartsForAlertId }, { tilesAlerts, eventTiles }]) => {
        //todo check if we need it
        let updatedTableAlerts: Dictionary<WallAlert> = { ...tableAlertsNew };
        const alertIdsToMakePlaybackSessionsFree: string[] = [];
        const onRemovedAlert: Action[] = [];

        Object.values(tilesAlerts)
          .forEach((alert, index) => {
            let tilesForThisAlert: number[] = eventTiles[alert.eventId] ?? [];
            tilesForThisAlert = tilesForThisAlert.concat(eventTiles['allEventsTiles']);
            if (!!tilesForThisAlert && !!tilesForThisAlert?.length) {
              const emptyTile = [...tilesForThisAlert].find(tileNumber => {
                return !tilesAlertsNew[tileNumber];
              });
              /**
               * Could not be 0, cause if this action triggered, means that new alerts came and if 0 tile is empty it be occupated
               * And it could not be empty by clean threshold than other tiles, cause all of them always has SAME duration. It means
               * tile 0 always be clean later than others.
               */
              if (emptyTile) {
                updatedTableAlerts = {
                  ...updatedTableAlerts,
                  [alert._id]: { ...alert, tile: null, tileColor: null },
                };
                tilesAlertsNew[emptyTile] = alert;
              } else {
                alertIdsToMakePlaybackSessionsFree.push(alert._id);
                // onRemovedAlert.push(WallV2Actions.onRemovedAlertFromTile({ alert }));
              }

            }
          });

        /**
         * No refresh tiles if nothing changed inside it
         */
        if (Object.values(tilesAlertsNew)?.length) {
          return [
            WallV2Actions.updateTableAlertsAndTileAlerts({ tableAlerts: updatedTableAlerts, tilesAlerts: tilesAlertsNew }),
            WallV2Actions.refreshAlertsTiles(),
            WallV2Actions.makePlaybackSessionsFreeAndStartNewPlaybackIfNeeds({ alertIdsToMakePlaybackSessionsFree, newPlaybackStartsForAlertId }),
            ...onRemovedAlert,
          ];
        } else {
          //todo check cause when it could happen
          return [
            WallV2Actions.getAlertsTableSuccess({ alerts: updatedTableAlerts }),
            WallV2Actions.refreshAlertsTiles(),
          ];
        }

      }),
    ),
  );


  public findAndCleanTileByAlertId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.findAndCleanTileByAlertId),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ alertId }, { tableAlerts }]) => {
          const tilesAlertsArray = tableAlerts ? Object.values(tableAlerts) : [];
          const alert = tilesAlertsArray.find(alert => alert._id === alertId);
          // could be 0
          if (typeof alert?.tile != 'undefined') {
            return [
              WallV2Actions.clearTileInThreshold({ tile: alert.tile }),
              // WallV2Actions.onRemovedAlertFromTile({ alert }),
            ];
          } else {
            return [SharedActions.doNothing()];
          }
        },
      ),
    ),
  );


  public refreshAlertsTiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.refreshAlertsTiles),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([, { tableAlerts, tilesAlerts, selectedWall }]) => {
          const wallTileSettings = selectedWall.settings.tileSettings;
          const updatedAlerts: { [alertId: string]: WallAlert } = {};
          const alertIdTileMap: Dictionary<number> = {};
          /**
           * Get tile of each alerts based on tiles view
           */
          Object.keys(tilesAlerts)
            .forEach(tile => {
              const tileAlert = tilesAlerts[tile];
              // for case if  received alerts more than tiles.
              if (typeof tileAlert !== 'undefined') {
                alertIdTileMap[tileAlert._id] = parseInt(tile);
              }
            });

          const alertIds = Object.keys(tableAlerts) ?? [];
          /**
           * Refresh tile number of each alert
           */
          alertIds.forEach((alertId) => {
            const alert = tableAlerts[alertId];
            const tile = alertIdTileMap[alertId] ?? null;
            let colorIndex = null;
            if (tile === null || !this.ifExists(tile)) {
              if (this.delaysByAlertId[alertId]) {
                clearTimeout(this.delaysByAlertId[alertId]);
                delete this.delaysByAlertId[alertId];
              }
            } else {
              colorIndex = tile % 9;
            }
            updatedAlerts[alertId] = { ...alert, tile, tileColor: WallV2Model.colorValues[colorIndex] };
          });
          return [
            WallV2Actions.refreshAlertsTilesSuccess({ tableAlerts: updatedAlerts }),
            WallV2Actions.checkWebRtcConnections(),
          ];
        },
      ),
    ),
  );

  public serverRequestSaveWall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.serverRequestSaveWall),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([, { selectedWall, selectedSetIndex }]) => {
          if ('_id' in selectedWall) {
            return this.wallV2Service.update({ ...selectedWall, name: !!selectedWall.name ? selectedWall.name : 'Untitled wall' })
              .pipe(
                switchMap((res) => {
                  return [
                    WallV2Actions.setIsSaving({ isSaving: false }),
                    WallV2Actions.serverRequestSaveWallSuccess({ id: selectedWall._id }),
                    SharedActions.showMessage({ success: 'Wall has been updated' }),
                  ];
                }),
                catchError(() => {
                  return [
                    WallV2Actions.setIsSaving({ isSaving: false }),
                    SharedActions.showMessage({ error: 'Fail to update wall' }),
                    SharedActions.doNothing(),
                  ];
                }),
              );
          } else {
            throw Error('_id is required for update');
          }

        },
      ),
      share(),
    ));


  public startDeleteWallById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.deleteWallById),
      mergeMap(({ id }) => {
          return this.wallV2Service.delete(id)
            .pipe(
              mergeMap((res) => {
                return [
                  WallV2Actions.setIsSaving({ isSaving: false }),
                  SharedActions.showMessage({ success: 'Wall has been removed' }),
                  WallV2Actions.deleteWallByIdSuccess({ id }),
                ];
              }),
              catchError(() => {
                return [
                  WallV2Actions.setIsSaving({ isSaving: false }),
                  SharedActions.showMessage({ error: 'Fail to remove wall' }),
                  WallV2Actions.deleteWallByIdFail({ id }),
                ];
              }),
            );
        },
      ),
      share(),
    ));

  public getAlertEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.getAlertEvents),
      switchMap(() => {
          return this.alertEventsService.getAllNoPaging()
            .pipe(
              switchMap((res) => {
                const eventMap: Dictionary<AlertV2Document> = {};
                res.forEach(item => {
                  eventMap[item._id] = item;
                });
                return [
                  WallV2Actions.getAlertEventsSuccess({ alertEvents: res }),
                  WallV2Actions.setEventMap({ eventMap }),
                ];
              }),
              catchError((res) => {
                return [
                  WallV2Actions.getAlertEventsFail(),
                ];
              }),
            );
        },
      ),
      share(),
    ));


  public removeTileEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.removeTileEvent),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ cameraId, eventId, tile }, { selectedWall, selectedSetIndex }]) => {
        const tiles = [...selectedWall.sets[selectedSetIndex].tiles];
        const tileEvents = tiles[tile].events ?? [];
        const existingEventsMap: Dictionary<WallV2Model.SelectedEvent> = {};
        tileEvents.forEach(tileEvent => {
          const key = `${tileEvent.cameraId}-${tileEvent.eventId}`;
          existingEventsMap[key] = tileEvent;
        });
        const keyToRemove = `${cameraId}-${eventId}`;
        delete existingEventsMap[keyToRemove];
        const updatedEventsArray = Object.values(existingEventsMap);

        tiles[tile] = {
          ...tiles[tile],
          events: updatedEventsArray.length ? updatedEventsArray : null,
        };
        const sets = _.cloneDeep(selectedWall.sets);
        sets[selectedSetIndex] = {
          ...sets[selectedSetIndex],
          tiles,
        };
        return of(WallV2Actions.setSelectedWall({
          wall: {
            ...selectedWall,
            sets: sets,
          },
        }));
      }),
    ),
  );

  public setTileEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.setEventsToSelectedTile),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ events, tile }, { selectedWall, selectedSetIndex }]) => {
        const tiles = [...selectedWall.sets[selectedSetIndex].tiles];

        tiles[tile] = {
          ...WallV2Model.defaultSetTile,
          events: events.length ? events : null,
        };
        const sets = _.cloneDeep(selectedWall.sets);
        sets[selectedSetIndex] = {
          ...sets[selectedSetIndex],
          tiles,
        };
        return of(WallV2Actions.setSelectedWall({
          wall: {
            ...selectedWall,
            sets: sets,
          },
        }));
      }),
    ),
  );


  public saveQuickView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.saveQuickView),
      switchMap(({ name }) =>
        [
          WallV2Actions.setIsSaving({ isSaving: true }),
          WallV2Actions.setWallName({ name }),
          WallV2Actions.serverRequestCreateWall(),
        ]),
    ),
  );

  public getTilesOfEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.getWallByIdSuccess, WallV2Actions.setSelectedSetIndex, WallV2Actions.slideToNextSet),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([, { selectedWall, selectedSetIndex }]) => {
        const eventPositionLookup: { [eventId: string]: number[] } = {};
        const allEventsTiles: number[] = [];
        selectedWall.sets[selectedSetIndex].tiles.forEach((position, tile) => {
          if (position?.events) {
            position.events.forEach(event => {
              const eventId = event?.eventId;
              if (eventId) {
                if (eventPositionLookup[eventId]) {
                  eventPositionLookup[eventId].push(tile);
                  eventPositionLookup[eventId] = [...new Set(eventPositionLookup[eventId])];
                } else {
                  eventPositionLookup[eventId] = [tile];
                }
              }
            });
          }
          if (position.allEvents) {
            allEventsTiles.push(tile);
          }
          eventPositionLookup['allEventsTiles'] = allEventsTiles;
        });
        return of(WallV2Actions.setTilesOfEvent({ eventTiles: eventPositionLookup }));
      }),
    ),
  );


  public getTableAlertsTrigger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.setAlertTableFilterFrequency),
      switchMap(() =>
        [
          WallV2Actions.getTableAlertsByFilters(),
        ]),
    ),
  );

  public archiveAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.archiveAlert),
      mergeMap(({ alert, isArchive }) => {
        return this.alertMonitoringService.archiveAlert(alert._id, isArchive)
          .pipe(
            mergeMap(() => {
              if (isArchive) {
                return [
                  WallV2Actions.findAndRemoveAlertFromTableAndTiles({ alertId: alert._id }),
                  WallV2Actions.makePlaybackSessionAsFreeForAlertId({ alertId: alert._id }),
                ];
              } else
                return [
                  WallV2Actions.archiveAlertCanceled({ alertId: alert._id }),
                ];

            }),
            catchError(response => {
              return [
                WallV2Actions.archiveAlertFail(),
                this.catchError(response)];
            }),
          );
      }),
    ),
  );


  public archiveAlertCanceled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.archiveAlertCanceled),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ alertId }, { tableAlerts }]) => {
          const updatedTableAlerts = {
            ...tableAlerts,
            [alertId]: {
              ...tableAlerts[alertId],
              archivedAt: null,
            },
          };
          return [
            WallV2Actions.refreshAlertsTilesSuccess({ tableAlerts: updatedTableAlerts }),
          ];
        },
      ),
    ),
  );

  public onRemovedAlertFromTile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.onRemovedAlertFromTile),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ alert }, { tableAlerts, tilesAlerts, selectedWall, selectedSetIndex }]) => {
        const alertCamera = alert?.selectedCamera;
        const noLivekitSessionId = Object.values(tilesAlerts)
          .filter(tileAlert => !tileAlert?.liveKitSessionId);
        if (noLivekitSessionId?.length) {
          console.log('Some of the tiles doesnt have session id');
          console.log(noLivekitSessionId);
        }
        if (alert.liveKitSessionId) {
          // cleanup livekit session if needed
          const selectedSet = selectedWall?.sets[selectedSetIndex];
          const cameras = selectedSet?.tiles?.map(tile => tile?.camera)
            ?.filter(camera => !!camera && camera.cameraId === alertCamera.cameraId);
          if (!cameras?.length) {
            const existingSessionAlerts = Object.values(tilesAlerts)
              .filter(tileAlert => tileAlert?.liveKitSessionId === alert?.liveKitSessionId);
            if (!existingSessionAlerts?.length || (existingSessionAlerts?.length === 1 && existingSessionAlerts?.[0]?._id === alert?._id)) {
              console.log(`cleanup livekit session ${alert?.liveKitSessionId}`);
              const room = this.livekitService.getRoomBySessionId(alert?.liveKitSessionId);
              this.livekitService.disconnect(room, alert?.liveKitSessionId);
            }
          }
        }
        return [];
      })));

  public findAndRemoveAlertFromTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.findAndRemoveAlertFromTableAndTiles),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ alertId }, { tableAlerts, tilesAlerts }]) => {
          const alert = tableAlerts[alertId];
          const isTileAlert = Object.values(tilesAlerts)
            ?.find(tileAlert => tileAlert?._id === alertId);
          const updatedTableAlerts = { ...tableAlerts };
          delete updatedTableAlerts[alertId];
          const updatedTiles = { ...tilesAlerts };
          delete updatedTiles[alert?.tile];
          const actions: Action[] = [
            WallV2Actions.updateTableAlertsAndTileAlerts({ tableAlerts: updatedTableAlerts, tilesAlerts: updatedTiles }),
            WallV2Actions.makePlaybackSessionAsFreeForAlertId({ alertId }),
          ];
          if (isTileAlert) {
            // actions.push(WallV2Actions.onRemovedAlertFromTile({ alert }));
          }
          return actions;
        },
      ),
    ),
  );

  public selectSessionBeforeStartPlayback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.selectSessionBeforeStartPlayback),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([{ dtoArrayBeforeStart }, { playbacks, freePlaybackSessions }]) => {
          const startPlaybackActions: Action[] = [];
          const updatedFreePlaybackSessions = { ...freePlaybackSessions };

          dtoArrayBeforeStart.forEach(playbackDtoStart => {
            const freePlaybackSessionsArray = Object.keys(updatedFreePlaybackSessions);
            let freeSessionId = null;
            if (freePlaybackSessionsArray?.length) {
              const freePlaybackSessionWithEdgeId = freePlaybackSessionsArray.find(key => {
                const object = freePlaybackSessions[key];
                return object.edgeId === playbackDtoStart.edgeId;
              });
              if (freePlaybackSessionWithEdgeId) {
                freeSessionId = updatedFreePlaybackSessions[freePlaybackSessionWithEdgeId].sessionId;
                delete updatedFreePlaybackSessions[freePlaybackSessionWithEdgeId];
              }
            }
            startPlaybackActions.push(
              WallV2Actions.startPlayback({ wallPlaybackStartDto: { ...playbackDtoStart, sessionId: freeSessionId } }),
            );
          });

          return [
            WallV2Actions.setUpdatedFreePlaybackSessions({ updatedFreePlaybackSessions: updatedFreePlaybackSessions }),
            ...startPlaybackActions,
          ];
        },
      ),
    ),
  );


  public clearTileInThreshold$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.clearTileInThreshold),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([{ tile }, { playbacks, tilesAlerts }]) => {
          const alertId = tilesAlerts[tile]?._id;
          const updatedTilesAlerts = { ...tilesAlerts };
          delete updatedTilesAlerts[tile];

          return [
            WallV2Actions.clearTileInThresholdSuccess({ tilesAlerts: updatedTilesAlerts }),
            WallV2Actions.refreshAlertsTiles(),
            WallV2Actions.makePlaybackSessionAsFreeForAlertId({ alertId: alertId }),
          ];
        },
      ),
    ),
  );


  public runCheckInactivePlaybackSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.runCheckInactivePlaybackSessions),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([, { freePlaybackSessions }]) => {
          const updatedFreePlaybackSessions: Dictionary<WallV2Model.WallPlaybackSession> = {};
          const timeouts = Object.keys(freePlaybackSessions);
          timeouts.forEach(timeUnix => {
            const timeToDeleteSession = moment
              .unix(+timeUnix)
              .add(this.PLAYBACK_SESSION_CHECK_INTERVAL / 1000, 'seconds')
              .unix();
            const now = moment()
              .unix();
            if (timeToDeleteSession > now) {
              updatedFreePlaybackSessions[timeUnix] = freePlaybackSessions[timeUnix];
            }
          });
          return of(WallV2Actions.setUpdatedFreePlaybackSessions({ updatedFreePlaybackSessions: updatedFreePlaybackSessions }));
        },
      ),
    ),
  );

  public updateFreePlaybackSessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.makePlaybackSessionAsFreeForAlertId),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([{ alertId }, { freePlaybackSessions, playbacks }]) => {
          const playBackSession = playbacks[alertId];
          const timestampKeySec = moment()
            .unix();

          const checkSessionTimeout = setTimeout(() => {
            this.store$.dispatch(WallV2Actions.runCheckInactivePlaybackSessions());
          }, this.PLAYBACK_SESSION_CHECK_INTERVAL);

          this.playbackSessionsCheckTimeouts.push(checkSessionTimeout);

          const updatedFreePlaybackSessions = {
            ...freePlaybackSessions,
          };
          const updatedPlaybacks = { ...playbacks };

          if (playBackSession) {
            updatedFreePlaybackSessions[timestampKeySec] = playBackSession;
            delete updatedPlaybacks[alertId];
          }

          return [
            WallV2Actions.setPlaybackAndFreeSessions({ playbacks: updatedPlaybacks, freePlaybackSessions: updatedFreePlaybackSessions }),
            WallV2Actions.cleanPlaybackFailersForAlertId({ alertId: alertId }),
          ];
        },
      ),
    ),
  );


  public makePlaybackSessionsFreeAndStartNewPlaybackIfNeeds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.makePlaybackSessionsFreeAndStartNewPlaybackIfNeeds),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([{ alertIdsToMakePlaybackSessionsFree, newPlaybackStartsForAlertId }, { playbacks, freePlaybackSessions }]) => {
          const updatedPlaybacks = { ...playbacks };
          const updatedFreePlaybackSessions = {
            ...freePlaybackSessions,
          };

          alertIdsToMakePlaybackSessionsFree.forEach((alertId, index) => {
            /**
             * Handle case if request is not finished but new alerts happen and shift alerts which are not finished.
             */
            if (playbacks[alertId]) {
              const playBackSession = { ...playbacks[alertId] };
              console.log('playBackSession', playBackSession);
              if (!playBackSession) {
                throw Error('playback is undefined');
              }
              /**
               * add index to make 100% unique timestamp
               */
              const timestampKeySec = moment()
                .unix() + index;
              delete playBackSession.url;
              delete playBackSession.cameraId;
              updatedFreePlaybackSessions[timestampKeySec] = playBackSession;

              const checkSessionTimeout = setTimeout(() => {
                this.store$.dispatch(WallV2Actions.runCheckInactivePlaybackSessions());
              }, this.PLAYBACK_SESSION_CHECK_INTERVAL);

              this.playbackSessionsCheckTimeouts.push(checkSessionTimeout);
              delete updatedPlaybacks[alertId];
            }
          });

          return [
            WallV2Actions.setPlaybackAndFreeSessions({ playbacks: updatedPlaybacks, freePlaybackSessions: updatedFreePlaybackSessions }),
            WallV2Actions.selectSessionBeforeStartPlayback({ dtoArrayBeforeStart: newPlaybackStartsForAlertId }),
          ];
        },
      ),
    ),
  );

  public playbackRestartByAlertId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.playbackRestartByAlertId),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      filter(([{ alertId }, { playbackStartErrors }]) => !!playbackStartErrors[alertId]),
      mergeMap(([{ alertId }, { playbackStartErrors }]) => {
          const updatedPlaybackStartErrors = { ...playbackStartErrors };
          const error = playbackStartErrors[alertId];
          delete updatedPlaybackStartErrors[alertId];
          return [
            WallV2Actions.selectSessionBeforeStartPlayback({ dtoArrayBeforeStart: [error.playbackDto] }),
            WallV2Actions.setUpdatedPlaybackFails({ updatedPlaybackStartErrors }),
          ];
        },
      ),
    ),
  );

  public cleanPlaybackFailersForAlertId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.cleanPlaybackFailersForAlertId),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      filter(([{ alertId }, { playbackStartErrors }]) => !!playbackStartErrors[alertId]),
      mergeMap(([{ alertId }, { playbackStartErrors }]) => {
          const updatedPlaybackStartErrors = { ...playbackStartErrors };
          delete updatedPlaybackStartErrors[alertId];
          return [
            WallV2Actions.setUpdatedPlaybackFails({ updatedPlaybackStartErrors }),
          ];
        },
      ),
    ),
  );


  public startPlayback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.startPlayback),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([{ wallPlaybackStartDto }, { playbackStartErrors, selectedWall }]) => {
        const alertSettings: WallV2Model.AlertSettings = selectedWall.settings.alertSettings;
        const isPlaybackEnabled = alertSettings.display === AlertsV2DisplaySettings.PLAYBACK || alertSettings.picInPic === AlertsV2DisplaySettings.PLAYBACK;
        if (!isPlaybackEnabled) {
          return [SharedActions.doNothing()];
        }
          const playbackStartDto: LiveStreamModels.StartHlsPlaybackRequest = {
            locationId: wallPlaybackStartDto.locationId,
            edgeId: wallPlaybackStartDto.edgeId,
            cameraId: wallPlaybackStartDto.cameraId,
            start: wallPlaybackStartDto.timestamp - 20000,
            end: wallPlaybackStartDto.timestamp + 20000,
            smartStorage: true,
            sessionId: wallPlaybackStartDto?.sessionId,
          };

          return this.camerasService.startHlsPlaybackV2(playbackStartDto)
            .pipe(
              mergeMap(res => {
                return of(WallV2Actions.checkIfAlertsStillOnTile({ response: { ...res, edgeId: wallPlaybackStartDto.edgeId, videoCurrentTimestamp: 0 }, alertId: wallPlaybackStartDto.alertId }));
              }),
              catchError(err => {
                let error: WallV2Model.WallPlaybackErrorResponse = {
                  status: err.status,
                  message: err.statusText,
                };
                const errorObject = err.error;
                if (errorObject) {
                  error.status = errorObject.status;
                  error.message = errorObject.message;
                }
                console.log(err);
                return of(WallV2Actions.startPlaybackFail({ playbackDto: wallPlaybackStartDto, error: error }));
              }),
            );
        },
      ),
    ),
  );


  public startPlaybackSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.checkIfAlertsStillOnTile),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([{ response, alertId }, { tableAlerts, freePlaybackSessions }]) => {
          if (!isNull(tableAlerts[alertId].tile)) {
            return of(WallV2Actions.setPlaybackForAlert({ alertId, response }));
          } else {
            const updatedFreePlaybackSessions = { ...freePlaybackSessions };
            const timestampKeySec = moment()
              .unix();
            const copyResponsePlayback = { ...response };
            delete copyResponsePlayback.url;
            updatedFreePlaybackSessions[timestampKeySec] = copyResponsePlayback;
            return of(WallV2Actions.setUpdatedFreePlaybackSessions({ updatedFreePlaybackSessions }));
          }
        },
      ),
    ),
  );

  public onQueryParamsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.onQueryParamsChanged),
      switchMap(({ query }) => {
          return [
            WallV2Actions.setListLoader({ listLoader: true }),
            WallV2Actions.resetPaging(),
            WallV2Actions.getWalls({ query }),
          ];
        },
      ),
    ),
  );

  public checkWebRtcConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.checkWebRtcConnections),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      mergeMap(([, { tableAlerts, tilesAlerts, selectedWall, selectedSetIndex }]) => {
          let cameraTiles = selectedWall.sets[selectedSetIndex].tiles;
          if (!cameraTiles) {
            throw Error('No tiles in wall');
          }
          const edgeCameraIds = [];
          cameraTiles.forEach(tile => {
            if (tile?.camera) {
              edgeCameraIds.push({ edgeId: tile.camera.edgeId, cameraId: tile.camera.cameraId });
            }
          });

          const tileAlertsArray = Object.values(tilesAlerts);
          tileAlertsArray.forEach(alert => {
            if (alert?.cameraId) {
              edgeCameraIds.push({ edgeId: alert.edgeId, cameraId: alert.cameraId });
            }
          });
          this.webRtcService.cleanExcept(edgeCameraIds);
          return [
            SharedActions.doNothing(),
          ];
        },
      ),
    ),
  );

  public getYoutubeList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.getYoutubeList),
      switchMap(() =>
        this.youtubeService.getAllByFilters()
          .pipe(
            switchMap(res => {
              const documents = {};
              res.forEach(item => {
                documents[item._id] = {
                  ...item, url:
                    this.getYouTubeEmbedUrl(item.url),
                };
              });
              return of(WallV2Actions.getYoutubeListSuccess({ documents }));
            }),
            catchError(err => {
              return of(WallV2Actions.getYoutubeListFail());
            }),
          ),
      )));


  public setTileYoutube$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.setTileYoutube),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ youtube, tile }, { selectedWall, selectedSetIndex }]) => {
        const tiles = [...selectedWall.sets[selectedSetIndex].tiles];
        tiles[tile] = {
          ...WallV2Model.defaultSetTile,
          youtubeId: youtube._id,
        };
        const sets = _.cloneDeep(selectedWall.sets);
        sets[selectedSetIndex] = {
          ...sets[selectedSetIndex],
          tiles,
        };
        return of(WallV2Actions.setSelectedWall({
          wall: {
            ...selectedWall,
            sets: sets,
          },
        }));
      }),
    ),
  );

  public dragTileToTile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.dragTileToTile),
      withLatestFrom(this.store$.pipe(select(state => state.wallV2State))),
      switchMap(([{ fromTile, toTile }, { selectedWall, selectedSetIndex }]) => {
        const tiles = [...selectedWall.sets[selectedSetIndex].tiles];
        const newFromTile = { ...tiles[toTile] };
        tiles[toTile] = { ...tiles[fromTile] };
        tiles[fromTile] = {
          ...newFromTile,
        };
        const sets = _.cloneDeep(selectedWall.sets);
        sets[selectedSetIndex] = {
          ...sets[selectedSetIndex],
          tiles,
        };
        return of(WallV2Actions.setSelectedWall({
          wall: {
            ...selectedWall,
            sets: sets,
          },
        }));
      }),
    ),
  );

  public getWallLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WallV2Actions.getWallLocations),
      withLatestFrom(
        this.store$.pipe(select(state => state.wallV2State)),
      ),
      switchMap(([, { selectedWall }]) => {
        const locationIds = [];
        let isAllOgrEvents = false;
        selectedWall.sets.forEach(set => {
          set.tiles.forEach(tile => {
            if (tile?.camera) {
              locationIds.push(tile.camera.locationId);
            }
            if (tile?.events) {
              tile.events.forEach(tileEvent => {
                locationIds.push(tileEvent.locationId);
              });
            }
            if (tile.allEvents) {
              if (!isAllOgrEvents) {
                isAllOgrEvents = tile.allEvents;
              }
            }
          });
        });
        return this.sharedApiService.getAllLocations(locationIds)
          .pipe(
            map(res => {
              return this.computeLocationLookup(res);
            }),
            switchMap(res => {
              return [
                // WallV2Actions.getWallLocationsSuccess({ locations: res.locations }),
                LocationActions.GetLocationsSuccess({ payload: res.locations }),
                LocationActions.SetLocationsLookup({ lookup: res.locationLookup }),
                EdgeActions.GetLocationEdgesNoBackendCall({ payload: res.edges }),
                CameraActions.GetLocationEdgesCamerasSnapshots({ payload: res.cameras }),
                CameraActions.GetLocationEdgesCamerasSuccess({ payload: res.cameras }),
              ];
            }),
            catchError(err => {
              return of(WallV2Actions.getWallLocationsFail());
            }),
          );
      }),
      catchError(err => {
        return of(
          LocationActions.GetLocationsFail({
            message: err?.error?.message || 'uknown error occured',
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private wallV2Service: WallV2Service,
    private alertsService: AlertsService,
    private alertEventsService: AlertEventsService,
    private alertMonitoringService: AlertMonitoringService,
    private camerasService: CamerasService,
    private webRtcService: WebrtcService,
    private youtubeService: YoutubeService,
    private livekitService: LivekitService,
    private sharedApiService: SharedApiService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

  private getEventIdsForCurrentSet(set: WallV2Model.WallSet): { eventIds: Dictionary<boolean>, isAllEvents: boolean } {
    let isAllEvents: boolean = false;
    let eventIds: Dictionary<boolean> = {};
    set?.tiles?.map(tile => {
      const tileEvents = tile?.events;
      if (tileEvents) {
        tileEvents
          .forEach(ev => {
            eventIds[ev.eventId] = true;
          });
      }
      if (tile.allEvents) {
        isAllEvents = true;
      }
    });
    return { eventIds, isAllEvents };
  }

  private ifExists(emptyTile: number) {
    return typeof emptyTile !== 'undefined';
  }

  private getYouTubeEmbedUrl(videoUrl: string) {
    try {
      const videoId = getYoutubeVideoIdFromUrl(videoUrl);
      return `https://www.youtube.com/embed/${videoId}`;
    } catch (error) {
      console.error('Error parsing YouTube URL:', error['message']);
      return null;
    }
  }

  private computeLocationLookup(res: LocationModel.LocationItem[]) {
    const locationLookup = {};
    const edges = res
      .map(e => {
        locationLookup[e._id] = e;
        return !!e.edges ? Object.values(e.edges!) : [];
      })
      .flat();
    const cameras = !!edges?.length ? edges.map(e => (!!e.cameras ? Object.values(e.cameras!) : []))
      .flat() : [];
    return {
      locations: res,
      edges,
      cameras,
      locationLookup,
    };
  }
}
