<ng-container *ngrxLet="selectIsDeveloper$; let isDeveloper">
  <ng-container *ngrxLet="selectedSet$; let currentSet">
    <ng-container *ngrxLet="selectSelectedWall$; let selectSelectedWall">
      <ng-container *ngrxLet="selectWallSets$; let selectWallSets">
        <ng-container *ngrxLet="selectWallSetIndex$; let selectWallSetIndex">
          <ng-container *ngrxLet="selectIsSaving$; let selectIsSaving">
            <ng-container *ngrxLet="selectIsSelectedWallRemoving$; let selectIsDeleting">
              <ng-container *ngrxLet="selectHasWallAlert$; let selectHasWallAlert">
                <ng-container *ngrxLet="selectYoutubeList$; let youtubeList">
                  <ng-container *ngrxLet="locations$; let locations">
                    <ng-container *ngrxLet="selectAlertEvents$; let selectAlertEvents">
                      <div *ngIf="{
          isViewMode:isViewMode$|async,
          isEditMode:isEditMode$|async,
          isQuickLiveViewMode:isQuickLiveViewMode$|async,
          } as conditions" class="flex-col h-100"
                           [class.disabled]="selectIsSaving || selectIsDeleting"
                      >
                        @if (currentSet) {
                          <ng-container>
                            <ui-header-with-breadcrumbs>

                              @if (!conditions.isViewMode) {
                                <div class="flex-row end-center gap-8">

                                  @if (conditions.isEditMode) {
                                    <span class="ui-icon-settings ui-static-icon-button-32 c-p"
                                          matTooltip="Wall settings"
                                          (click)="openWallSettings()"
                                    >
                        </span>
                                    <ui-button type="default" text="Discard" (clickAction)="discardEdit()">

                                    </ui-button>
                                    <ui-progress-button
                                      text1="Save"
                                      text2="Saved"
                                      *rbacOrgAllow="[permissions.WallEdit]"
                                      [loading]="selectIsSaving"
                                      (clickAction)="saveWall()"
                                    >
                                    </ui-progress-button>
                                  } @else if (conditions.isQuickLiveViewMode) {
                                    <span class="ui-icon-edit ui-static-icon-button-32 c-p"
                                          matTooltip="Edit wall"
                                          (click)="editQuickWall()"
                                    ></span>
                                    <span class="ui-icon-fullscreen-2 ui-static-icon-button-32 c-p"
                                          matTooltip="Fullscreen"
                                          (click)="fullScreen()"
                                    ></span>
                                    <ng-container *rbacOrgAllow="[permissions.WallCreate]">
                                      <span class="v-d"></span>
                                      <ui-button

                                        text="Save as wall"
                                        type="secondary"
                                        [loading]="selectIsSaving"
                                        (clickAction)="saveQuickView()"
                                      >
                                      </ui-button>
                                    </ng-container>

                                  } @else {
                                    <span class="ui-icon-settings ui-static-icon-button-32 c-p"
                                          matTooltip="Wall settings"
                                          (click)="openWallSettings()"
                                    >
                        </span>
                                    <ui-progress-button
                                      *rbacOrgAllow="[permissions.WallCreate]"
                                      text1="Create wall"
                                      text2="Created"
                                      [loading]="selectIsSaving"
                                      (clickAction)="createWall()"
                                    >
                                    </ui-progress-button>
                                  }

                                </div>
                              } @else {
                                <div class="flex-row end-center gap-8" *ngrxLet="selectSelectedWall$;let selectedWall">
                                  <div class="flex-row center-center">
                                    <mat-icon *ngIf="selectedWall.settings.isPrivate"
                                              svgIcon="lock"
                                              class="ui-mat-icon-16"
                                              matTooltip="Private"
                                    ></mat-icon>
                                    <mat-icon *ngIf="!selectedWall.settings.isPrivate"
                                              svgIcon="public"
                                              class="ui-mat-icon-16"
                                              matTooltip="Public"
                                    ></mat-icon>
                                  </div>
                                  <mat-select class="ui-selector"
                                              [value]="selectWallSetIndex"
                                              (selectionChange)="changeSet($event)"
                                  >
                                    @for (set of selectWallSets; track set; let i = $index) {
                                      <mat-option [value]="i">
                                        {{ set.name }}
                                      </mat-option>
                                    }
                                  </mat-select>
                                  <!--                                  <span class="ui-icon-share ui-static-icon-button-32 c-p"-->
                                  <!--                                        matTooltip="Share"-->
                                  <!--                                        (click)="shareWall(selectedWall)"-->
                                  <!--                                  ></span>-->
                                  <ng-container *ngrxLet="selectIsMuted$;let selectIsMuted">
                                    @if (selectHasWallAlert) {
                                      <span
                                        [class.ui-icon-mute]="selectIsMuted"
                                        [class.ui-icon-speaker]="!selectIsMuted"
                                        [class.selected]="!selectIsMuted"
                                        class="ui-mat-icon-button-16 delete-icon c-p"
                                        [matTooltip]="selectIsMuted?'Unmute':'Mute'"
                                        matTooltipClass="sidebar-tooltip"
                                        (click)="mute(selectIsMuted)"
                                      ></span>
                                    }

                                  </ng-container>
                                  <span class="ui-icon-fullscreen-2 ui-static-icon-button-32 c-p"
                                        matTooltip="Fullscreen"
                                        (click)="fullScreen()"
                                  ></span>
                                  <span class="v-d"></span>
                                  <span class="ui-icon-edit ui-static-icon-button-32 c-p"
                                        matTooltip="Edit wall"
                                        (click)="goToEditWall(selectedWall)"
                                  ></span>
                                  <span class="ui-icon-delete ui-static-icon-button-32 c-p" matTooltip="Delete wall"
                                        (click)="deleteWall(selectedWall);"
                                  ></span>
                                </div>
                              }

                            </ui-header-with-breadcrumbs>
                            <div class="h-100" cdkDropListGroup
                                 [class.p-8]="conditions.isViewMode"
                                 [class.p-24]="!conditions.isViewMode"
                            >
                              <div class="flex-row h-100 w-100 gap-16">

                                @if (!conditions.isViewMode && !conditions.isQuickLiveViewMode) {
                                  <div class="panel flex-col gap-16 h-100">
                                    <input class="ui-input ui-input-40"
                                           placeholder="Untitled wall"
                                           [formControl]="wallNameFormControl"
                                    >
                                    <div class="flex-col h-100">
                                      <div class="scroll-wrapper">
                                        <ng-scrollbar class="custom-scrollbar">
                                          <div class="flex-col gap-8 h-100 scrollable-content">
                                            @if (!!locations?.length) {
                                              <app-ui-accordion>
                                                <span header class="label">Cameras</span>
                                                <div body>
                                                  <ng-container *ngrxLet="locations$;let locations">
                                                    <div class="flex-col gap-16">
                                                      <ui-search placeholder="Search for cameras"
                                                                 (onInput)="searchCamera($event)"
                                                      ></ui-search>
                                                      <div class="locations">
                                                        @for (location of locations; track location._id; let i = $index) {
                                                          <location-row [location]="location" [isExpanded]="i === 0"
                                                          ></location-row>
                                                        }

                                                      </div>
                                                    </div>
                                                  </ng-container>
                                                </div>
                                              </app-ui-accordion>
                                            }
                                            @if (!!selectAlertEvents?.length) {
                                              <app-ui-accordion [disabled]="selectWallSets.length>1"
                                                                [withButton]="true"
                                                                matTooltipText="Cannot add alerts: remove the sets from the wall to continue"
                                              >
                                                <div header class="flex-row space-between-center w-100">
                                                  <span class="label">Alerts</span>
                                                  <span class="ui-icon-settings icon-hover"
                                                        [class.disabled]="selectWallSets.length>1"
                                                        (click)="openAlertSettingsModal();$event.stopPropagation()"
                                                  ></span>
                                                </div>
                                                <div body class="h-100">
                                                  <wall-alerts-panel></wall-alerts-panel>
                                                </div>
                                              </app-ui-accordion>
                                            }
                                            @if (youtubeList?.length) {
                                              <app-ui-accordion *ngIf="{draggableYoutubeIndex$:draggableYoutubeIndex$|async} as conditions">
                                                <span header class="label">Youtube</span>
                                                <div body cdkDropList
                                                     cdkDropListSortingDisabled="true"
                                                >
                                                  @for (item of youtubeList; track item; let i = $index) {
                                                    @if (i === conditions.draggableYoutubeIndex$) {
                                                      <div class="flex-row gap-10 youtube-row start-center c-p">
                                                        <ng-container *ngTemplateOutlet="youtubePlaceholder">

                                                        </ng-container>
                                                      </div>
                                                    }
                                                    <div class="flex-row gap-10 youtube-row start-center c-p"
                                                         cdkDrag
                                                         [cdkDragData]="{youtube:item, type:wallCdkDropDataType.Youtube}"
                                                         (cdkDragStarted)="onDragYoutubeStart(i)"
                                                         (cdkDragEnded)="onDragYoutubeEnd()"
                                                    >
                                                      <span class="ui-icon-youtube"></span>
                                                      <div class="h-100 truncated-youtube">
                                                        <ui-truncated-html>
                                                          {{ item.name }}
                                                        </ui-truncated-html>
                                                      </div>

                                                      <ng-template #youtubePlaceholder>
                                                        <span class="ui-icon-youtube"></span>
                                                        <div class="w-100 h-100">
                                                          <ui-truncated-html>
                                                            {{ item.name }}
                                                          </ui-truncated-html>
                                                        </div>
                                                      </ng-template>

                                                      <ng-template cdkDragPreview>
                                                        <div class="flex-row center-center alert-drag-preview"
                                                        >
                                                          Add youtube
                                                        </div>

                                                      </ng-template>
                                                    </div>
                                                  }
                                                </div>
                                              </app-ui-accordion>
                                            }
                                          </div>
                                        </ng-scrollbar>
                                      </div>

                                    </div>
                                  </div>
                                }

                                <div class="tiles flex-col h-100 gap-16">

                                  @if (!conditions.isViewMode && !conditions.isQuickLiveViewMode) {
                                    <div class="flex-row end-center gap-8">
                                      <div matTooltip="Cannot add sets: remove alert tiles from the wall to continue"
                                           [matTooltipDisabled]="!selectHasWallAlert"
                                      >
                                        <div class="flex-row start-center"
                                             [class.disabled]="selectHasWallAlert"
                                        >
                                          <mat-select class="ui-selector ui-selector-40 set-selector"
                                                      [value]="selectWallSetIndex"
                                                      (selectionChange)="changeSet($event)"
                                          >
                                            @for (set of selectWallSets; track set; let i = $index) {
                                              <mat-option [value]="i">
                                                {{ set.name }}
                                              </mat-option>
                                            }
                                          </mat-select>
                                          <span class="ui-icon-set-rotation sets c-p"
                                                matTooltip="Sets configuration"
                                                (click)="openSetSettingsModal()"
                                          ></span>
                                        </div>
                                      </div>
                                      <span class="ui-icon-layout ui-static-icon-button-42"
                                            matTooltip="Layout"
                                            (click)="openLayoutConfigModal()"
                                      ></span>
                                    </div>
                                    <v2-set-layout [set]="currentSet"
                                                   class="h-100"
                                                   [viewMode]="conditions.isViewMode"
                                                   (onCameraSelected)="onCameraSelected($event)"
                                                   (onCleanTile)="cleanTile($event)"
                                                   (onEventSelected)="onEventsSelected($event)"
                                                   (onEventRemoved)="removeSelectedEvent($event)"
                                                   (onEventsChanged)="onEventsChanged($event)"
                                                   (onYoutubeSelected)="onYoutubeSelected($event)"
                                                   (onTileDrag)="onTileDragged($event)"
                                    >

                                    </v2-set-layout>
                                  }
                                  @if (conditions.isViewMode || conditions.isQuickLiveViewMode) {
                                    <div class="h-100 flex-col gap-8 full-screen-container" #fullScreenContainer
                                         appElementLoaded
                                         (onLoaded)="fullScreenLoaded()"
                                    >
                                      @if (selectHasWallAlert) {
                                        <div class="alerts-wrapper"
                                             mwlResizable
                                             #alertContainer
                                             (resizing)="onResizing($event);"
                                             (resizeEnd)="onResizeEnd()"
                                        >
                                          <alerts-incoming-v2 [alerts]="selectTableAlerts$|ngrxPush"
                                                              [locationLookup]="selectLocationLookup$|ngrxPush"
                                                              #alertsIncomingComponent
                                          ></alerts-incoming-v2>
                                          <div mwlResizeHandle [resizeEdges]="{ bottom: true }" class="resize-icon">
                                            <span class="ui-icon-dot"></span>
                                            <span class="ui-icon-dot"></span>
                                            <span class="ui-icon-dot"></span>
                                          </div>
                                        </div>
                                      }
                                      @if (currentSet.duration) {
                                        <div class="h-100 flex-row" *ngIf="{rotationTime:rotationTime$|async,
                          selectNextSetIndex:selectNextSetIndex$|async}as con"
                                        >
                                          <div class="w-100 h-100"
                                               *ngFor="let set of selectWallSets; let i = index"
                                               [class.hidden]="selectWallSetIndex !== i"

                                          >
                                            <ng-container
                                              *ngIf="i == selectWallSetIndex || (i == con.selectNextSetIndex && con.rotationTime > (selectWallSets[selectWallSetIndex].duration / 1000) - PRELOAD_SET_TIME_SECONDS)"
                                            >
                                              <v2-set-layout [set]="selectWallSets[i]"
                                                             class="h-100 w-50"
                                                             [viewMode]="conditions.isViewMode || conditions.isQuickLiveViewMode"
                                              >
                                              </v2-set-layout>
                                            </ng-container>
                                          </div>
                                        </div>
                                      } @else {
                                        <v2-set-layout [set]="currentSet"
                                                       class="h-100 w-100"
                                                       [viewMode]="conditions.isViewMode || conditions.isQuickLiveViewMode"
                                        >
                                        </v2-set-layout>
                                      }


                                    </div>
                                  }


                                </div>


                              </div>

                            </div>
                          </ng-container>
                        } @else {
                          <div class="preloader-wrapper">
                            <ui-preloader [color]="preloaderColors.blue"></ui-preloader>
                          </div>
                        }

                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loader>
</ng-template>

