<ng-container *ngIf="{
collapsed:collapsed$|async
} as conditions"
>
  <div class="location" [class.expanded]="!conditions?.collapsed">

    <div class="location-name flex-row space-between-center c-p" (click)="toggleLocation();$event.stopPropagation()">
      <div class="flex-row start-center gap-16">
        @if (isMulti) {
          <div [class.disabled]="!edges.length || isQuery">
            <mat-checkbox type="checkbox"
                          color="primary"
                          name="select-all"
                          [checked]="selected"
                          (click)="$event.stopPropagation()"
                          (change)="selectLocation($event)"
            ></mat-checkbox>
          </div>
        }

        <div class="flex-row start-center gap-8">
          <!--          <span class="ui-icon-location"></span>-->
          {{ location.name }}
        </div>

      </div>
      <span
        *ngIf="edges.length"
        class="ui-icon-expand-arrow"
        [class.rotated]="!conditions.collapsed"
      ></span>
    </div>
    <div *ngIf="!conditions.collapsed" class="camera-list flex-col gap-6">
      <camera-selector-camera-row *ngFor="let camera of cameras | sortByName; trackBy:trackByCamera"
                                  [camera]="getCameraById(camera?.cameraId) | async"
                                  [selectedCameras]="selectedCameras"
                                  [disabledCameras]="disabledCameras"
                                  [isMulti]="isMulti"
                                  (onCameraSelected)="selectCamera($event)"
      >
      </camera-selector-camera-row>
    </div>
    <div *ngIf="!conditions.collapsed" class="sublocations flex-col gap-8" [class.mt-16]="subLocations?.length">
      <camera-selector-sublocation-row *ngFor="let sublocation of subLocations; trackBy:trackBy"
                                       [sublocation]="sublocation"
                                       [selectedCameras]="selectedCameras"
                                       [isCollapsed]="conditions.collapsed"
                                       [disabledCameras]="disabledCameras"
                                       [isMulti]="isMulti"
                                       (onCameraSelected)="selectCamera($event)"
                                       (onSublocationSelected)="selectSublocation($event)"
                                       [filteredCameras]="filteredCameras"
                                       [isQuery]="isQuery"

      >

      </camera-selector-sublocation-row>
    </div>
  </div>
</ng-container>
