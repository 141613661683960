import { LocationModel } from '../locations/location.model';
import { PermissionModel } from '@models/permission.model';
import { CameraPulsationState } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.reducer';
import { PulsationModels } from '@models/pulsation.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { Auth0 } from '../authentication/auth0.model';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment/moment';
import { HttpStatusCode } from '@angular/common/http';
import { errorMsg } from '@consts/text.const';
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;
import { Archive, ArchiveStatus } from '@models/archive.model';
import { environment } from '../../environments/environment';
import ArchiveDocument = Archive.ArchiveDocument;
import { ElementRef } from '@angular/core';

export const smartSearch = <T>(input: any[], query: string, searchProperty: string | string[]): T[] => {
  if (query) {
    const queries = query
      .split(' ')
      .filter(x => x !== '')
      .map(x => x.toUpperCase());
    return input?.filter(x => {
      let upperX = '';

      if (searchProperty) {
        if (Array.isArray(searchProperty)) {
          searchProperty.forEach(p => (upperX += x[p]?.toUpperCase() ?? ''));
        } else {
          upperX = x[searchProperty].toUpperCase();
        }
      } else {
        upperX = x.toUpperCase();
      }

      return queries.every(q => upperX.includes(q));
    });
  } else {
    return input;
  }
};

export const validateEmail = email => {
  const emailRegex = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i);
  return emailRegex.test(email);

};

export const validatePhone = phone => {
  const re = /^\+\d{10}/im;
  return re.test(phone);
};


export const hideEmail = (email: string): string => {
  const openIndexes = [0];
  let emailMarkIndex;
  let domainDotIndex;
  email.split('')
    .forEach((value, index) => {
      if (value === '@') {
        emailMarkIndex = index;
        openIndexes.push(index);
        openIndexes.push(index + 1);
      }
      if (emailMarkIndex) {
        if (value === '.') {
          domainDotIndex = index;
          openIndexes.push(index);
          openIndexes.push(index - 1);
        }
      }
    });

  return email.split('')
    .map((value, index) => {
      return openIndexes.includes(index) ? value : (index > domainDotIndex ? value : '*');
    })
    .join('');
};


export const sortArrByField = <T>(array: T[], field: string, direction: 'asc' | 'desc' = 'desc'): T[] => {
  return array.sort((a, b) => {
    if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
    if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
    return 0;
  });
};

/**
 * Filter locations, edges, cameras by query
 * @param locations
 * @param queryFilter
 */
export const filterLocationByQuery = (locations: LocationModel.LocationItem[],
                                      queryFilter: string, filterLocationId?: string, filterCameraIds?: string[]) => {

  let result: LocationModel.LocationItem[] = [];

  if (!!filterLocationId) {
    locations = locations.filter(location => location._id === filterLocationId);
  }
  // return locations;
  locations.forEach(location => {

    const edges = Object.keys(location.edges);
    let filteredEdges = {};

    edges.forEach(edgeId => {

      const edge = location.edges[edgeId];
      const cameras = !!edge?.cameras ? Object.values(edge.cameras) : [];
      let filteredCameras = {};

      cameras.forEach(camera => {
        const cameraId = camera.edgeOnly.cameraId;

        if (!!filterCameraIds && filterCameraIds.includes(cameraId)) {
          return;
        }

        const isCameraNameOk = camera?.edgeOnly?.name?.toUpperCase()
          .includes(queryFilter.toUpperCase());


        if (isCameraNameOk) {
          filteredCameras = {
            ...filteredCameras,
            [cameraId]: camera,
          };
        }
      });

      const isEdgeNameOk = edge.name.toUpperCase()
        .includes(queryFilter.toUpperCase());

      if (Object.values(filteredCameras).length > 0 || isEdgeNameOk) {
        filteredEdges = {
          ...filteredEdges,
          [edgeId]: {
            ...edge,
            cameras: { ...filteredCameras },
          },
        };
      }
    });

    const isLocationNameOk = location.name.toUpperCase()
      .includes(queryFilter.toUpperCase());

    if (Object.values(filteredEdges).length > 0 || isLocationNameOk) {
      result = [...result, {
        ...location,
        edges: { ...filteredEdges },
      }];
    }

  });
  // this.locations$.next(result)
  return result;
};

/**
 * ids the array of all entities from child to parent
 * @param selectedPermission
 * @param permissions
 * @param ids
 */
export const checkPermission = (selectedPermission: PermissionModel.Permissions, permissions: {
  permissions: string[],
  entityIds: string[]
}, ids: string[]): boolean => {
  let isInherit = false;
  if (ids.length) {
    /**
     * Check if permission specified for this entity or parent
     */
    const permissionId = ids.find(item => {
      return permissions.entityIds.includes(item);
    });

    if (permissionId) {
      const result = permissions.permissions.find(permission => {
        return permission.indexOf(`${permissionId}:${selectedPermission}`) > -1;
      });
      return !!result;
    }

    /**
     * If not specified use global perm
     */
    isInherit = !permissionId;
  } else {
    // if array of ids empty check in global permissions
    isInherit = true;
  }
  if (isInherit) {
    return permissions.permissions.includes(selectedPermission);
  }
  return false;

};


export const removeArrayElementIfExists = (array: any[], element: any): any[] => {
  const copyArray = [...array];
  const selectedIndex = copyArray.indexOf(element);
  if (selectedIndex > -1) {
    copyArray.splice(selectedIndex, 1);
  } else {
    copyArray.push(element);
  }
  return copyArray;
};


export const entityStatusByEdgeStatus = (entityId: string, edges: Dictionary<ComponentStatusDisplay>, entities: CameraPulsationState): PulsationModels.ComponentStatus => {
  const edgeId = entities.entities[entityId]?.edgeId;
  const edgePulsationStatus = edges[edgeId];
  if (!edgePulsationStatus || (edgePulsationStatus !== PulsationModels.ComponentStatusDisplay.Online && edgePulsationStatus !== PulsationModels.ComponentStatusDisplay.Offline)) {
    return PulsationModels.ComponentStatus.Unknown;
  }
  const storagePulsation = entities.entities[entityId];
  return PulsationModels.getFilteredPulsationStatus(storagePulsation);
};

export const parseJwt = (token: string): Auth0.JwtUserData => {
  if (!token) {
    return null;
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+')
    .replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0)
          .toString(16)).slice(-2);
      })
      .join(''),
  );

  const payload = JSON.parse(jsonPayload);
  return payload as Auth0.JwtUserData;
};

export const parseGuestJwt = (token: string): { accessInfo: { orgId: string } } | Auth0.SharedTokenData => {
  if (!token) {
    return null;
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+')
    .replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0)
          .toString(16)).slice(-2);
      })
      .join(''),
  );

  const payload = JSON.parse(jsonPayload);
  return payload as { accessInfo: { orgId: string } };
};

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return control.value.password === control.value.confirmPassword
    ? null
    : { PasswordNoMatch: true };
};

export const passwordStrength: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = control?.value;
  const lower = value?.search(/(?=.*[a-z])/) < 0;
  const upper = value?.search(/(?=.*[A-Z])/) < 0;
  const specialSymbol = value?.search(/(?=.*[!@#$%^&*])/) < 0;
  const digit = value?.search(/[0-9]/) < 0;
  const length = value?.length < 8;

  const errors = {
    lower,
    upper,
    specialSymbol,
    digit,
    length,
  };

  if (!lower) {
    delete errors['lower'];
  }

  if (!upper) {
    delete errors['upper'];
  }

  if (!specialSymbol) {
    delete errors['specialSymbol'];
  }
  if (!digit) {
    delete errors['digit'];
  }
  if (!length) {
    delete errors['length'];
  }


  return Object.values(errors)?.length
    ? errors
    : null;
};


export const calculateExpiryDate = (expiresInSeconds: number): string => {
  const expiresAt = moment()
    .add(expiresInSeconds, 'second');
  return JSON.stringify(expiresAt.valueOf());
};

export const auth0ErrorHandler = (err: { status: HttpStatusCode, error: any }): string => {
  let errorDescription = 'Unknown error';
  if (err?.status === HttpStatusCode.Unauthorized) {
    errorDescription = errorMsg.incorrectPassword;
  }
  if (err?.status === HttpStatusCode.NotFound) {
    errorDescription = 'Token is expired';
  }
  if (err?.error?.message?.indexOf('PasswordStrengthError') > -1) {
    errorDescription = errorMsg.weakPassword;
  }
  if (err?.error?.message?.indexOf('String is too short') > -1) {
    errorDescription = errorMsg.shortPassword;
  }
  if (err?.error?.message?.indexOf('User is not found') > -1) {
    errorDescription = errorMsg.userNotFound;
  }
  return errorDescription;
};

export const atLeastTwoWordsValidator = (control: AbstractControl): { invalid: string } => {
  const valueArray = control.value.trim()
    .split(' ');
  return valueArray.length > 1
    ? null : { invalid: 'Value should has at least 2 words' };
};

export const JsonParseIfValid = <T>(str: string): T | null => {
  try {
    return JSON.parse(str) as T;
  } catch (e) {
    return null;
  }
};

export const getArchiveCloudPreviewUri = (archive: ArchiveDocument) => {
  return archive?.status === ArchiveStatus.COMPLETED && archive?.videoFormat === 'hls'
    ? `${environment.playbackArchiveUrl}/archives/${archive.edgeId}/${archive.cameraId}/${archive.sessionId}/preview/${archive.name}.m3u8`
    : getArchiveCloudUri(archive);
};

export const getArchiveCloudUri = (archive: ArchiveDocument) => {
  return `${environment.playbackArchiveUrl}/archives/${archive.edgeId}/${archive.cameraId}/${archive.sessionId}/${archive.name}.${archive.videoFormat}`;
};

export const isFullscreen = (): boolean => {
  return !!document?.fullscreenElement || (window?.innerWidth === screen?.width && (window?.innerHeight === screen?.height || window?.innerHeight === screen?.height - 37));
};

export const getYoutubeVideoIdFromUrl = (videoUrl: string): string => {
  const url = new URL(videoUrl);
  if (url.hostname === 'www.youtube.com' || url.hostname === 'youtube.com') {
    const videoId = url.searchParams.get('v'); // Extract the 'v' parameter
    return videoId;
  } else if (url.hostname === 'youtu.be') {
    const videoId = url.pathname.slice(1); // Extract the video ID from the path
    return videoId;
  }
  return null;
};

export const searchParentComponent = (elementRef: ElementRef, condition: (element: HTMLElement) => boolean): HTMLElement | null => {
  let currentElement: HTMLElement | null = elementRef.nativeElement;

  while (currentElement) {
    if (condition(currentElement)) {
      return currentElement; // Found the parent matching the condition
    }
    currentElement = currentElement.parentElement; // Move to the next parent
  }

  return null; // No matching parent found
};

export const openFullScreen = async (element: HTMLElement) => {
  const elem = element;
  if (!isFullscreen() || document?.fullscreenElement !== element) {
    if (elem.requestFullscreen) {
      await elem.requestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      if (document.fullscreenElement !== null) {
        await document.exitFullscreen();
      }
    } else if (document['mozCancelFullScreen']) {
      await document['mozCancelFullScreen']();
    } else if (document['webkitCancelFullScreen']) {
      await document['webkitCancelFullScreen']();
    }
  }
};

