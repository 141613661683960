import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { WallV2Model } from '@models/wall-v2.model';
import { BehaviorSubject, debounce, filter, Observable, Subject, take, takeUntil, timer } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { AppState } from '../../../../../../store/app.state';
import { Dictionary } from '@ngrx/entity/src/models';
import { EdgeCamera } from '../../../../../../cameras/camera.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { AlertV2Document } from '@models/alerts-v2.model';
import { AlertEntry } from '../../../../../../development/alerts.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocationSelectors } from '@states/location/location.selector-types';
import { WebrtcPlayerV2Component } from '../../../../../../shared/webrtc-player/webrtc-player-v2.component';
import { YoutubeModel } from '@models/youtube.model';
import { DomSanitizer } from '@angular/platform-browser';
import { openFullScreen, searchParentComponent } from '../../../../../../helpers/common.helpers';

@UntilDestroy()
@Component({
  selector: 'v2-wall-tile',
  templateUrl: './v2-wall-tile.component.html',
  styleUrl: './v2-wall-tile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V2WallTileComponent implements OnInit, OnDestroy {

  @ViewChild('hint') hint: ElementRef;
  @ViewChild('player') player: WebrtcPlayerV2Component;
  @ViewChild('fullScreenContainer')
  fullScreenContainer: ElementRef;
  @Input() index: number;

  @Input() tile: WallV2Model.SetTile;
  @Input() isSelected: boolean = false;
  @Input() checkPermissions: boolean = true;

  @Input() viewMode: boolean = true;
  @Input() isDragHover: boolean = false;
  @Output() onCleanTile: EventEmitter<number> = new EventEmitter<number>();
  @Output() onAlertRemoved: EventEmitter<{ eventId: string, cameraId: string }> = new EventEmitter<{ eventId: string, cameraId: string }>();
  @Output() onAlertsChanged: EventEmitter<WallV2Model.SelectedEvent[]> = new EventEmitter<WallV2Model.SelectedEvent[]>();
  @Output() onTileClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public selectWallTileSettings$: Observable<WallV2Model.TilePreviewSettings> = this.store$.pipe(select(WallV2Selectors.selectWallTileSettings));
  public selectWallAlertsSettings$: Observable<WallV2Model.AlertSettings> = this.store$.pipe(select(WallV2Selectors.selectWallAlertSettings));

  public selectCamerasLookup$: Observable<Dictionary<EdgeCamera.CameraItem>> = this.store$.pipe(
    select(CameraSelectors.selectCamerasLookup),
  );

  public selectEventsLookup$: Observable<Dictionary<AlertV2Document>> = this.store$.pipe(
    select(WallV2Selectors.selectEventMap),
  );
  public selectYoutubeMap$: Observable<Dictionary<YoutubeModel.YoutubeMongoDocument>> = this.store$.select(WallV2Selectors.selectYoutubeList);
  public forceHideTile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectAlertByTile$: Observable<AlertEntry>;
  public showAlertComponent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected readonly Object = Object;
  private alertId = null;

  private hintDebouncer = new Subject<void>();
  private resetHintSubject = new Subject<void>();
  private forceHideTileTimeout: any;

  public showedHint = false;
  public hintHidden = false;

  constructor(private store$: Store<AppState>, private renderer: Renderer2,
              private sanitizer: DomSanitizer,
              private element: ElementRef) {
  }


  public ngOnInit() {
    this.selectAlertByTile$ = this.store$.select(WallV2Selectors.selectAlertByTileId(this.index));

    this.selectAlertByTile$
      .pipe(untilDestroyed(this),
        filter(res => !!res))
      .subscribe(res => {
        if (res._id !== this.alertId) {
          this.showAlertComponent$.next(false);
          setTimeout(() => {
            this.showAlertComponent$.next(true);
          }, 100);
        }
        this.alertId = res?._id;
        console.log(this.index, 'destroy', this.alertId);
      });

    if (!this.isEmptyTile && !!this.tile?.camera && this.viewMode) {
      this.hintDebouncer.pipe(
          untilDestroyed(this),
          debounce(() =>
            timer(1000)
              .pipe(
                takeUntil(this.resetHintSubject),
              ),
          ),
          take(1))
        .subscribe(() => {
          this.showHint();
        });

      this.resetHintSubject.pipe(take(1))
        .subscribe(() => {
          this.hintHidden = true;
        });
    }

    if (this.isYoutubeTile) {
      const parentHidden = searchParentComponent(this.element, (element) => element.classList.contains('hidden'));
      this.forceHideTile$.next(!!parentHidden);
      this.forceHideTileTimeout = setTimeout(() => {
        this.forceHideTile$.next(false);
      }, (WallV2Model.PRELOAD_SET_TIME_SECONDS - 1) * 1000);
    }

  }

  public get now() {
    return Date.now();
  }

  public removeSelectedEvent(ev: { eventId: string, cameraId: string }) {
    this.onAlertRemoved.emit(ev);
  }

  public mouseMove(event: MouseEvent) {
    if (this.hintHidden || this.isEmptyTile || !this.tile?.camera || this.player?.inZoom() || !this.viewMode) {
      return;
    }
    if (this.showedHint) {
      this.hideHint();
    }
    const screenWidth = window.innerWidth;
    const x = Math.min(Math.max(event.x - 217, 20), screenWidth - 430);
    if (this.hint?.nativeElement) {
      this.renderer.setStyle(this.hint.nativeElement, 'left', x + 'px');
      this.renderer.setStyle(this.hint.nativeElement, 'top', event.y + 20 + 'px');
      this.hintDebouncer.next();
    }
  }

  public mouseLeave() {
    this.resetHintSubject.next();

  }

  private showHint() {
    if (this.isEmptyTile || !this.tile?.camera || this.player?.inZoom() || !this.viewMode) {
      return;
    }
    if (this.hint?.nativeElement) {
      this.renderer.setStyle(this.hint?.nativeElement, 'transition', '0.3s');
      this.renderer.setStyle(this.hint?.nativeElement, 'opacity', 1);
      this.showedHint = true;
    }

  }

  private hideHint() {
    if (this.hint?.nativeElement) {
      this.renderer.setStyle(this.hint?.nativeElement, 'transition', '0s');
      this.renderer.setStyle(this.hint?.nativeElement, 'opacity', 0);
      this.hintHidden = true;
    }
  }

  async fullScreen() {
    const elem = this.fullScreenContainer?.nativeElement;
    await openFullScreen(elem);
  }

  public locationName(locationId: string): Observable<string> {
    return this.store$.pipe(select(LocationSelectors.selectLocationName(locationId)));
  }

  public get isAlertTile(): boolean {
    return this.tile.allEvents || !!this.tile.events;
  }

  public get isEmptyTile(): boolean {
    return !this.tile.camera && !this.isAlertTile && !this.tile.youtubeId;
  }

  public get isYoutubeTile(): boolean {
    return !!this.tile.youtubeId;
  }

  public ngOnDestroy() {
    if (this.forceHideTileTimeout) {
      clearTimeout(this.forceHideTileTimeout);
    }
  }
}
