import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EdgeCamera } from '../../../cameras/camera.model';
import { KeyValuePairs } from '../../../core/interfaces';
import { UiZoneSelectorDialogResult } from '../../ui-kit/ui-zone-selector/ui-zone-selector-dialog/ui-zone-selector-dialog.component';
import { AlertEvent } from '@models/alert-events.model';
import { Observable } from 'rxjs';
import { EdgeStatusService } from '../../../edge/edge-status.service';
import { CameraEventSelectionResult } from '@models/camera.model';
import { PulsationModels } from '@models/pulsation.model';
import { StateChange } from 'ng-lazyload-image';

@Component({
  selector: 'camera-row-sub',
  templateUrl: './camera-row-sub.component.html',
  styleUrls: ['./camera-row-sub.component.scss'],
})
export class CameraRowSubComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('cameraBlock') cameraBlock: ElementRef;

  @Input() camera: EdgeCamera.CameraItem;
  @Input() withAlerts: boolean = false;
  @Input() edgeSelection: boolean = false;
  @Input() zonesCache: KeyValuePairs<UiZoneSelectorDialogResult> = {};
  @Input() cameraEventsLookup: { [key: string]: AlertEvent[] } = {};
  @Input() selectedAlerts: { [key: string]: boolean } = {};
  @Input() zoneSelection: boolean = false;
  @Input() selectedCameras: { [key: string]: boolean } = {};
  @Input() narrow = false;
  @Input() multi: boolean = false;
  @Input() selectOnly = false;
  @Input() selectedCameraId: string;

  @Output() onCameraSelected: EventEmitter<EdgeCamera.CameraItem> = new EventEmitter<EdgeCamera.CameraItem>();
  @Output() onEventSelected: EventEmitter<CameraEventSelectionResult> = new EventEmitter<CameraEventSelectionResult>();
  @Output() onZonesSelection: EventEmitter<EdgeCamera.CameraItem> = new EventEmitter<EdgeCamera.CameraItem>();

  @Input() expandEvents = true;

  public imageLoader: boolean = true;
  public imgLoadFailed: boolean = false;

  public hidden: boolean = false;
  public timestamp = new Date().getTime();

  // public nameWidth: number;

  constructor(private edgeStatusService: EdgeStatusService, private cd: ChangeDetectorRef) {
  }

  public get nameWidth(): number {
    return this.cameraBlock?.nativeElement?.clientWidth - 200;
  }

  ngAfterViewInit(): void {
    // this.nameWidth = this.cameraBlock?.nativeElement?.clientWidth - 100;
    // this.cd.detectChanges();
  }

  ngOnInit(): void {
  }

  public toggleEvents() {
    this.expandEvents = !this.expandEvents;
  }

  public ngOnChanges(changes: SimpleChanges) {
  }

  public selectCamera(camera: EdgeCamera.CameraItem): void {
    if (this.withAlerts) {
      const arrayForMap = this.cameraEventsLookup[camera.edgeOnly.cameraId] ?? [];
      const alertEvents = arrayForMap.map(alertEvent => {
        return {
          eventId: alertEvent._id,
          cameraId: this.camera.cameraId,
          edgeId: this.camera.edgeId,
          locationId: this.camera.locationId,
        };
      });
      const cameraWithEvents: EdgeCamera.CameraItem = {
        ...camera,
        alertEvents,
      };
      this.onCameraSelected.emit(cameraWithEvents);
    } else {
      this.onCameraSelected.emit(camera);
    }
  }

  public selectEvent(alert: AlertEvent): void {
    this.onEventSelected.emit({
      event: {
        eventId: alert._id,
        cameraId: this.camera.edgeOnly.cameraId,
        edgeId: this.camera.edgeId,
        locationId: this.camera.locationId,
      },
      camera: this.camera,
    });
  }

  public selectZones(camera: EdgeCamera.CameraItem) {
    this.onZonesSelection.emit(camera);
  }

  public hideEvents() {
    this.hidden = !this.hidden;
  }

  public getCameraPulsationStatus(cameraId: string): Observable<PulsationModels.ComponentStatus> {
    return this.edgeStatusService.getCameraPulsationStatus(cameraId);
  }

  public imgStateChange(event: StateChange) {
    switch (event.reason) {
      case 'start-loading':
        // The image is in the viewport so the image will start loading
        this.imageLoader = true;
        this.imgLoadFailed = false;
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.imageLoader = false;
        this.imgLoadFailed = false;
        break;
      case 'loading-failed':
        this.imageLoader = false;
        this.imgLoadFailed = true;
        break;
    }
  }


}
