export enum MenuKey {
  cameraEdit = 1, //avoid 0
  main,
  network,
  searchZones,
  privacy,
  details,
  configuration,
  swUpdate,
  data,
  zones,
  settings,
  notifications,
  assets,
  videoAudio,
  edgeEdit,
  uploadConfiguration,
  document,
  info,
  management,
  logs,
  searchSettings,
  pulsation,
  thumbnailsBitmap,
  uploadVideoStorage,
  uptime,
  analyticConfiguration,
  actions,
  alertDetails,
  cameras,
  variableCameras,
  objects,
  selectAlert,
  method,
  configure,
  uploadFile,
  previewData,
  import,
  storageSettings,
  scanCameras,
  ptz,
  multiple,
  muxer,
  pulsationState,
  speakers,
  addSpeaker,
  errorReport,
  ntp,
  coreInfo,
  eventType,
  eventTypeEdit,
  doors,
  people,
  testCamera,
  recovery,
  heartBeatStatus,
  vehicles,
  orgGroups,
  monitoring,
  loginFirewall,
  eventTypeCreate,
  certifications,
  appleTVList,
  appleTvListEdit,
  integrations,
  hlsManagement,
  location,
  dhcp,
  appleTvListCreate,
  externalStorage,
  externalStorageEdit,
  auth,
  display,
  devTeam,
  quotas,
  genea,
  kisi,
  shortcut,
  users,
  permissions,
  createScheduleList,
  scheduleList,
  alarmCreate,
  alarmList,
  alerts
}
