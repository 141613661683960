<div *ngIf="(descMatching || descLocationNameMatching || upperLocationNameMatching) && {
isExpanded:isExpanded$|async,
draggableIndex:draggableIndex$|async,
selectedCameraInTilesMap:selectSelectedCameraInTilesMap$|async
} as conditions" class="flex-col sublocation-row"
>
  <div class="flex-row space-between-center c-p" (click)="toggleExpand()">
    <div class="flex-row start-center gap-8">
      {{ sublocation.name }}
    </div>
    <span class="ui-icon-expand-arrow"></span>
  </div>
  <div *ngIf="conditions.isExpanded" class="mt-16 gap-8" id="cameraList"
       cdkDropList
       cdkDropListSortingDisabled="true"
  >
    @for (camera of cameras | sortByName; track camera.cameraId; let i = $index) {

      <div class="camera-wrapper c-p selected" [hidden]="i !== conditions.draggableIndex">
        <ng-container *ngTemplateOutlet="placeholder">

        </ng-container>
      </div>
      <div cdkDrag
           [cdkDragData]="{camera,type:wallCdkDropDataType.Camera}"
           class="camera-wrapper c-p"
           [class.selected]="conditions.selectedCameraInTilesMap[camera.cameraId]"
           (cdkDragStarted)="onDragStart(i)"
           (cdkDragEnded)="onDragEnd()"
      >
        <v2-camera-row [camera]="getCameraById(camera?.cameraId) | async">

        </v2-camera-row>

        <ng-template #placeholder>
          <v2-camera-row [camera]="getCameraById(camera?.cameraId) | async">

          </v2-camera-row>
        </ng-template>

        <ng-template cdkDragPreview>
          <div class="flex-row center-center camera-drag-preview"
          >
            Add camera
          </div>

        </ng-template>
      </div>
    }
  </div>
  <div *ngIf="conditions.isExpanded" class="mt-16 gap-8">
    @for (sublocation of sublocations; track sublocation._id; let i = $index) {
      <sublocation-row [sublocation]="sublocation" [isExpanded]="isExpanded">

      </sublocation-row>
    }
  </div>
</div>

