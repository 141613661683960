import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarPipe } from './user-avatar.pipe';
import { PeoplePipe } from './people.pipe';
import { StringToDatePipe } from './string-to-date';
import { locationAddressPipe } from './location-address';
import { LogLinkPipe } from './log-link.pipe';
import { TerminalMsgPipe } from './terminal-msg.pipe';
import { ActivityLogUserPipe } from './activity-log-user.pipe';
import { FromJsonPipe } from './from-json.pipe';
import { AddId } from './add-id';
import { TimeUnitTransform } from './time-unit-transform';
import { ObjToArrPipe } from './obj-to-arr';
import { LocationCameraCounterPipe } from './location-camera-counter.pipe';
import { LocationEdgeStatusTotalPipe } from './location-edge-status-total.pipe';
import { LocationCameraStatusTotalPipe } from './location-camera-status-total.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { WidgetVariablesFilterPipe } from './widget-variables-filter.pipe';
import { UiCalendarSelectedPipe } from './ui-calendar-selected.pipe';
import { LocationCameraHealthPipe } from './location-camera-health.pipe';
import { LocationPulsationHealthPipe } from './location-pulsation-health.pipe';
import { SecToMinsDurationPipe } from './sec-to-mins-duration.pipe';
import { ChunkPipe } from './chunk.pipe';
import { AbsPipe } from './abs.pipe';
import { TimeFromNowPipe } from './time-from-now.pipe';
import { CameraBestImagePipe } from './camera-best-image.pipe';
import { HexToRgbaPipe } from './hex-to-rgba.pipe';
import { LocationAnalyticStatusTotalPipe } from './location-analytic-status-total';
import { AlertNotificationLogStatusPipe } from './alert-notification-log-status.pipe';
import { CameraThumbnailImagePipe } from './camera-thumbnail-image.pipe';
import { SortByNamePipe } from './sort-by-name.pipe';

@NgModule({
  declarations: [
    UserAvatarPipe,
    PeoplePipe,
    StringToDatePipe,
    locationAddressPipe,
    LogLinkPipe,
    TerminalMsgPipe,
    ActivityLogUserPipe,
    FromJsonPipe,
    AddId,
    TimeUnitTransform,
    ObjToArrPipe,
    LocationCameraStatusTotalPipe,
    LocationCameraCounterPipe,
    LocationEdgeStatusTotalPipe,
    RelativeTimePipe,
    WidgetVariablesFilterPipe,
    UiCalendarSelectedPipe,
    LocationCameraHealthPipe,
    LocationPulsationHealthPipe,
    SecToMinsDurationPipe,
    ChunkPipe,
    AbsPipe,
    TimeFromNowPipe,
    CameraBestImagePipe,
    CameraThumbnailImagePipe,
    HexToRgbaPipe,
    LocationAnalyticStatusTotalPipe,
    AlertNotificationLogStatusPipe,
    SortByNamePipe,
  ],
  imports: [CommonModule],
  providers: [RelativeTimePipe, LocationEdgeStatusTotalPipe],
  exports: [
    UserAvatarPipe,
    PeoplePipe,
    StringToDatePipe,
    locationAddressPipe,
    LogLinkPipe,
    TerminalMsgPipe,
    ActivityLogUserPipe,
    FromJsonPipe,
    AddId,
    TimeUnitTransform,
    ObjToArrPipe,
    LocationCameraStatusTotalPipe,
    LocationCameraCounterPipe,
    LocationEdgeStatusTotalPipe,
    RelativeTimePipe,
    WidgetVariablesFilterPipe,
    UiCalendarSelectedPipe,
    LocationCameraHealthPipe,
    LocationPulsationHealthPipe,
    SecToMinsDurationPipe,
    ChunkPipe,
    AbsPipe,
    TimeFromNowPipe,
    CameraBestImagePipe,
    CameraThumbnailImagePipe,
    HexToRgbaPipe,
    LocationAnalyticStatusTotalPipe,
    AlertNotificationLogStatusPipe,
    SortByNamePipe,
  ],
})
export class PipesModule {
}
