import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationModel } from '../../../../locations/location.model';
import { MatCheckbox } from '@angular/material/checkbox';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { PipesModule } from '../../../../pipes/pipes.module';
import { LazyLoadImageModule, StateChange } from 'ng-lazyload-image';
import { UiCameraStatusComponent } from '../../../../shared/ui-kit/ui-camera-status/ui-camera-status.component';
import { Dictionary } from '@ngrx/entity/src/models';
import { SelectedCamera } from '@models/alert-events.model';
import { MatRadioButton } from '@angular/material/radio';

@Component({
  selector: 'camera-selector-camera-row',
  standalone: true,
  imports: [
    MatCheckbox,
    AsyncPipe,
    NgIf,
    PipesModule,
    LazyLoadImageModule,
    UiCameraStatusComponent,
    JsonPipe,
    MatRadioButton,
  ],
  templateUrl: './camera-row.component.html',
  styleUrl: './camera-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraRowComponent {
  @Input() camera: LocationModel.LocationCameraItem;
  @Input() selectedCameras: Dictionary<SelectedCamera>;
  @Input() isMulti: boolean = false;
  @Input() disabledCameras: Dictionary<string> = {};

  @Output() onCameraSelected: EventEmitter<SelectedCamera> = new EventEmitter<SelectedCamera>();
  public imageLoader: boolean = true;
  public imgLoadFailed: boolean = false;


  public imgStateChange(event: StateChange) {
    switch (event.reason) {
      case 'start-loading':
        // The image is in the viewport so the image will start loading
        this.imageLoader = true;
        this.imgLoadFailed = false;
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.imageLoader = false;
        this.imgLoadFailed = false;
        break;
      case 'loading-failed':
        this.imageLoader = false;
        this.imgLoadFailed = true;
        break;
    }
  }

  public selectCamera() {
    this.onCameraSelected.emit({ cameraId: this.camera.edgeOnly.cameraId, edgeId: this.camera.edgeId, locationId: this.camera.locationId });
  }

  public get selected() {
    return this.selectedCameras[this.camera.edgeOnly.cameraId];
  }
}
