import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, bufferTime, combineLatest, filter, lastValueFrom, map, Observable, of, startWith, switchMap, take, tap } from 'rxjs';
import { LocationSelectors } from '@states/location/location.selector-types';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { LocationModel } from '../../../../locations/location.model';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { filterLocationByQuery, JsonParseIfValid, smartSearch, sortArrByField } from '../../../../helpers/common.helpers';
import { WallV2Model } from '@models/wall-v2.model';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { WallV2Actions } from '@states/wall-v2/wall-v2.action-types';
import { MatDialog } from '@angular/material/dialog';
import { V2SelectLayoutModalComponent } from '../../modals/v2-select-layout-modal/v2-select-layout-modal.component';
import { WallSettingsModalComponent } from '../../modals/wall-settings-modal/wall-settings-modal.component';
import { AlertSettingsModalComponent } from '../../modals/alert-settings-modal/alert-settings-modal.component';
import { AlertV2Document } from '@models/alerts-v2.model';
import { SelectedCamera } from '@models/alert-events.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { WallSetSettingsModalComponent } from '../../modals/wall-set-settings-modal/wall-set-settings-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PreloaderColor } from '@enums/shared.enum';
import { WallV2Effects } from '@effects/wall-v2.effects';
import { ofType } from '@ngrx/effects';
import { routerSegments } from '@consts/routes';
import { AlertEntry } from '../../../../development/alerts.service';
import { SocketEvents } from '../../../../socket/socket.model';
import { SocketMainService } from '../../../../socket/socket-main.service';
import { ResizeEvent } from 'angular-resizable-element';
import { AlertsIncomingComponent } from './components/alerts-incoming-v2/alerts-incoming.component';
import { MatSelectChange } from '@angular/material/select';
import { SharedActions } from '@states/shared/shared.action-types';
import { ConfirmDialogType } from '../../../../shared/confirm-dialog/confirm-dialog.model';
import { SharedEffects } from '@effects/shared.effects';
import { CameraSelectorModalComponent } from '../../../../modals/camera-selector-modal/camera-selector-modal.component';
import { SaveQuickLiveViewAsWall } from '../../modals/save-quick-live-view-as-wall/save-quick-live-view-as-wall';
import { YoutubeModel } from '@models/youtube.model';
import { LocalStorageService } from '../../../../core/local-storage.service';
import { LocalStorageKeys } from '@consts/shared.const';
import { LivekitService } from '../../../../development/livekit.service';
import { UserSelectors } from '@states/user/user.selector-types';
import { PermissionModel } from '@models/permission.model';
import { ShareAccessComponent } from '../../../../shared/modals/share-access/share-access.component';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { CameraSelectorSublocationsModalComponent } from '../../../../modals/camera-selector-sublocations-modal/camera-selector-sublocations-modal.component';
import { CameraActions } from '@states/camera/camera.action-types';


@UntilDestroy()
@Component({
  selector: 'app-wall-v2-create',
  templateUrl: './wall-v2-create.component.html',
  styleUrl: './wall-v2-create.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WallV2CreateComponent implements OnInit, OnDestroy {
  public PRELOAD_SET_TIME_SECONDS = WallV2Model.PRELOAD_SET_TIME_SECONDS;
  public filteredLocations$: Observable<LocationModel.LocationItem[]> = new BehaviorSubject<LocationModel.LocationItem[]>([]);
  public cameraSearchQueryControl: UntypedFormControl = new UntypedFormControl([]);

  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(UserSelectors.isDeveloper));
  public selectedSet$: Observable<WallV2Model.WallSet> = this.store$.pipe(select(WallV2Selectors.selectCurrentSet));
  public selectNextSetIndex$: Observable<number> = this.store$.pipe(select(WallV2Selectors.selectNextSetIndex));
  public selectWallSets$: Observable<WallV2Model.WallSet[]> = this.store$.pipe(select(WallV2Selectors.selectWallSets));
  public selectWallSetIndex$: Observable<number> = this.store$.pipe(select(WallV2Selectors.selectSelectedSetIndex));
  public selectSelectedWall$: Observable<WallV2Model.WallMongoDocument | WallV2Model.WallCreateDto> = this.store$.pipe(select(WallV2Selectors.selectSelectedWall));
  public selectIsSaving$: Observable<boolean> = this.store$.pipe(select(WallV2Selectors.selectIsSaving));
  public selectHasWallAlert$: Observable<boolean> = this.store$.pipe(select(WallV2Selectors.selectHasWallAlert));
  public selectIsSelectedWallRemoving$: Observable<boolean> = this.store$.select(WallV2Selectors.selectIsSelectedWallRemoving);
  public selectIsMuted$: Observable<boolean> = this.store$.select(WallV2Selectors.selectIsMuted);
  public selectYoutubeList$: Observable<YoutubeModel.YoutubeMongoDocument[]> = this.store$.select(WallV2Selectors.selectYoutubeList)
    .pipe(map(item => Object.values(item)));
  public draggableYoutubeIndex$: BehaviorSubject<number> = new BehaviorSubject(null);

  public locations$: Observable<LocationModel.LocationItem[]> = this.store$.pipe(select(LocationSelectors.selectAllLocations));

  public rotationTime$: BehaviorSubject<number> = new BehaviorSubject(0);
  protected readonly Object = Object;
  public isViewMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isQuickLiveViewMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isEditMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectTableAlerts$: Observable<WallV2Model.WallAlert[]> = this.store$.select(WallV2Selectors.selectTableAlerts)
    .pipe(map(item => {
      const array = Object.values(item);
      return sortArrByField(array, 'timestamp', 'desc');
    }));
  public wallNameFormControl: FormControl = new FormControl<any>('');
  public selectLocationLookup$: Observable<{
    [key: string]: LocationModel.LocationItem;
  }> = this.store$.select(LocationSelectors.selectLocationLookup);
  @ViewChild('alertsIncomingComponent') alertsIncomingComponent: AlertsIncomingComponent;
  @ViewChild('alertContainer') alertContainer: ElementRef;

  public wallCdkDropDataType = WallV2Model.WallCdkDropDataType;
  @ViewChild('fullScreenContainer')
  fullScreenContainer: ElementRef;
  public permissions = PermissionModel.Permissions;
  private basicLayoutHeight: number;
  private basicAlertsHeight: number;
  public selectAlertEvents$: Observable<AlertV2Document[]> = this.store$.pipe(select(WallV2Selectors.selectAlertEvents));
  private rotationInterval: any;
  private rotationTimer: any;

  constructor(private store$: Store<AppState>,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private wallV2Effects: WallV2Effects,
              private router: Router,
              private socketMainService: SocketMainService,
              private renderer2: Renderer2,
              private sharedEffects: SharedEffects,
              private livekitService: LivekitService,
              private localStorageService: LocalStorageService) {
  }

  @HostListener('document:fullscreenchange', []) fullscreenChanged(ev) {
    if (this.fullScreenContainer?.nativeElement) {
      this.renderer2.removeStyle(this.fullScreenContainer.nativeElement, 'height');
    }
    if (this.alertContainer?.nativeElement) {
      this.renderer2.removeStyle(this.alertContainer.nativeElement, 'height');
    }
    this.basicLayoutHeight = null;
    this.basicAlertsHeight = null;
    this.localStorageService.removeItem('alertContainerNewHeight');
    this.localStorageService.removeItem('layoutHeight');
  }

  @HostListener('window:resize', []) onResize(ev) {
    if (this.fullScreenContainer?.nativeElement) {
      this.renderer2.removeStyle(this.fullScreenContainer.nativeElement, 'height');
    }
    if (this.alertContainer?.nativeElement) {
      this.renderer2.removeStyle(this.alertContainer.nativeElement, 'height');
    }
    this.basicLayoutHeight = null;
    this.basicAlertsHeight = null;
    this.localStorageService.removeItem('alertContainerNewHeight');
    this.localStorageService.removeItem('layoutHeight');
  }

  public ngOnInit() {
    this.store$.dispatch(WallV2Actions.resetToInitialState());

    const isMutedOptionStr = this.localStorageService.getItem(LocalStorageKeys.WALL_MUTED);
    if (isMutedOptionStr) {
      const booleanValue = JsonParseIfValid(isMutedOptionStr);
      if (booleanValue !== null) {
        this.mute(!booleanValue);
      }
    }

    this.selectTableAlerts$.subscribe(res => {
      // console.log('selectTableAlerts$');
    });
    if (this.rotationInterval) {
      clearInterval(this.rotationInterval);
      clearInterval(this.rotationTimer);
      this.rotationTime$.next(0);
    }
    const path = this.route.snapshot.url[0].path;
    this.breadCrumbsInit(path);

    this.cameraSearchQueryControl.valueChanges
      .pipe(startWith(''), untilDestroyed(this))
      .subscribe(query => {
        this.store$.dispatch(CameraActions.StartSearchQuery({ searchQuery: query }));
      });

    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res['id']) {
          this.store$.dispatch(WallV2Actions.getWallById({ id: res['id'] }));
        }
      });


    this.wallV2Effects.getWallById$
      .pipe(
        untilDestroyed(this),
        ofType(WallV2Actions.getWallByIdNotFound),
      )
      .subscribe(res => {
        this.router.navigate([routerSegments.notFound], { skipLocationChange: true });
      });

    combineLatest([
      this.isViewMode$,
      this.selectHasWallAlert$,
    ])
      .pipe(
        untilDestroyed(this),
        filter(([viewMode, wallHasAlert]) => viewMode && wallHasAlert),
        tap(() => this.store$.dispatch(WallV2Actions.getTableAlertsByFilters())),
        switchMap(() => {
            return this.socketMainService.consume<{ result: AlertEntry[] }>(SocketEvents.edgeIncomingAnalyticAlerts)
              .pipe(
                bufferTime(1000), // Buffer alerts for 1 second
                filter(alerts => {
                  console.log(alerts);
                  return alerts.length > 0;
                }),
                tap(alerts => {
                  this.store$.dispatch(WallV2Actions.alertSocketTriggered({ alerts }));
                }),
                untilDestroyed(this),
              );
          },
        ),
      )
      .subscribe();


    this.wallNameFormControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.store$.dispatch(WallV2Actions.setWallName({ name: res }));
      });

    this.wallV2Effects.serverRequestCreateWall$
      .pipe(
        untilDestroyed(this),
        ofType(WallV2Actions.serverRequestCreateWallSuccess),
      )
      .subscribe(res => {
        if (res.id) {
          this.router.navigate([routerSegments.wallV2, routerSegments.view, res.id]);
          return;
        }
        this.router.navigate([routerSegments.wallV2, routerSegments.list]);
      });

    this.selectSelectedWall$
      .pipe(
        filter(x => !!x),
        take(1),
      )
      .subscribe(res => {
        if (res.name) {
          this.wallNameFormControl.patchValue(res.name);
          this.route.snapshot.data['breadcrumbs'] = [
            {
              name: res.name,
            },
          ];

          this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve',
          });
        }
      });

    this.selectedSet$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x),
      )
      .subscribe(res => {
        if (this.isViewMode$.getValue()) {
          if (this.rotationInterval) {
            clearInterval(this.rotationInterval);
            clearInterval(this.rotationTimer);
            this.rotationTime$.next(0);
          }
          if (!!res.duration) {
            this.rotationInterval = setInterval(() => {
              this.store$.dispatch(WallV2Actions.slideToNextSet());
            }, res.duration);
            this.rotationTimer = setInterval(() => {
              let val = this.rotationTime$.getValue();
              val++;
              this.rotationTime$.next(val);
            }, 1000);
          }
        }

      });

    this.sharedEffects.confirmation$
      .pipe(
        untilDestroyed(this),
        ofType(SharedActions.showConfirmModalResultConfirm),
      )
      .subscribe(result => {
        switch (result.params['confirmType']) {
          case WallV2Model.WallConfirmModalType.DeleteWall:
            this.store$.dispatch(
              WallV2Actions.deleteWallById({ id: result.params['id'] }),
            );
            break;
          case WallV2Model.WallConfirmModalType.SaveQuickLiveViewAsWall:
            this.askQuickLiveViewName();
            break;
          case WallV2Model.WallConfirmModalType.DiscardEditWall:
            this.router.navigate([routerSegments.wallV2, routerSegments.list]);
            break;

        }

      });

    this.wallV2Effects.startDeleteWallById$
      .pipe(
        untilDestroyed(this),
        ofType(WallV2Actions.deleteWallByIdSuccess),
      )
      .subscribe(res => {
        this.router.navigate([routerSegments.wallV2]);
      });

    this.wallV2Effects.serverRequestSaveWall$
      .pipe(
        untilDestroyed(this),
        ofType(WallV2Actions.serverRequestSaveWallSuccess),
      )
      .subscribe(res => {
        if (res.id) {
          this.router.navigate([routerSegments.wallV2, routerSegments.view, res.id]);
          return;
        }
        this.router.navigate([routerSegments.wallV2, routerSegments.list]);
      });


  }

  public onCameraSelected(ev: WallV2Model.OnCameraSelectedData) {
    const camera = ev.camera;
    this.store$.dispatch(WallV2Actions.setTileCamera({
      tile: ev.tile, camera: {
        cameraId: camera.edgeOnly.cameraId,
        edgeId: camera.edgeId,
        locationId: camera.locationId,
      },
    }));
  }

  public onEventsSelected(ev: WallV2Model.OnEventSelectedData) {
    if (ev.isAllSelected) {
      this.store$.dispatch(WallV2Actions.dragAndDropTileEvents({
        tile: ev.tile, events: {}, allSelected: true,
      }));
    } else {
      const totalEventsMap = {
        ...ev.events,
      };
      if (ev.singleEvent) {
        totalEventsMap[ev.singleEvent._id] = ev.singleEvent;
      }

      const events = Object.values(totalEventsMap ?? {});
      const selectedEvents: Dictionary<WallV2Model.SelectedEvent> = {};
      /**
       * Need to be dictionary to be had better performance in case to avoid duplicates when drag and drop same events multiply time
       */
      events.forEach(event => {
        const syncCameras = event.cameraSync;
        Object.keys(syncCameras)
          //todo filter by exists cameras
          .forEach(cameraId => {
            const key = `${cameraId}-${event._id}`;
            const camera: SelectedCamera = syncCameras[cameraId];
            selectedEvents[key] = {
              cameraId,
              locationId: camera.locationId,
              edgeId: camera.edgeId,
              eventId: event._id,
            };
          });
      });

      this.store$.dispatch(WallV2Actions.dragAndDropTileEvents({
        tile: ev.tile, events: selectedEvents, allSelected: false,
      }));
    }
  }

  public cleanTile(tile: number) {
    console.log(tile);
    this.store$.dispatch(WallV2Actions.cleanSetTile({
      tile: tile,
    }));
  }

  public searchCamera(query: string) {
    this.cameraSearchQueryControl.setValue(query);
  }


  public openLayoutConfigModal(): void {
    this.dialog.open(V2SelectLayoutModalComponent, {
        panelClass: 'modal-no-padding',
        width: '685px',
        height: '540px',
        disableClose: true,
        data: {
          initialLoading: true,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.store$.dispatch(WallV2Actions.selectLayout({ layoutIndex: res?.selectedLayout }));
        }
      });
  }

  public openWallSettings() {
    this.dialog.open(WallSettingsModalComponent, {
        panelClass: 'modal-no-padding',
        width: '680px',
        height: '520px',
        disableClose: true,
        data: {
          initialLoading: true,
        },
      })
      .afterClosed()
      .subscribe((res: WallV2Model.WallSettings) => {
        if (res) {
          this.store$.dispatch(WallV2Actions.setWallSettings({
            isPrivate: res.isPrivate,
          }));
          this.store$.dispatch(WallV2Actions.setTileSettings({
            tileSettings: res.tileSettings,
          }));
        }

      });
  }

  public openAlertSettingsModal() {
    this.dialog.open(AlertSettingsModalComponent, {
        panelClass: 'modal-no-padding',
        width: '680px',
        height: '424px',
        disableClose: true,
        data: {
          initialLoading: true,
        },
      })
      .afterClosed()
      .subscribe((res: WallV2Model.AlertSettings) => {
        if (res) {
          this.store$.dispatch(WallV2Actions.setAlertSettings({ alertSettings: res }));
        }
      });
  }


  public createWall() {
    this.store$.dispatch(WallV2Actions.startCreateWall({ isSave: false }));
  }

  public saveWall() {
    this.store$.dispatch(WallV2Actions.startCreateWall({ isSave: true }));
  }

  public openSetSettingsModal() {
    this.dialog.open(WallSetSettingsModalComponent, {
        panelClass: 'modal-no-padding',
        width: '680px',
        height: '797px',
        disableClose: true,
        data: {
          initialLoading: true,
        },
      })
      .afterClosed()
      .subscribe((res: WallV2Model.WallSet[]) => {
        if (res) {
          this.store$.dispatch(WallV2Actions.updateSets({ sets: res }));
        }
      });
  }

  protected readonly preloaderColors = PreloaderColor;

  public breadCrumbsInit(path: string) {
    switch (path) {
      case routerSegments.create:
        this.openLayoutConfigModal();
        this.store$.dispatch(WallV2Actions.getAlertEvents());
        this.store$.dispatch(WallV2Actions.getYoutubeList());
        this.store$.dispatch(WallV2Actions.setSelectedWall({ wall: WallV2Model.defaultWallCreate }));
        break;
      case routerSegments.view:
        this.store$.dispatch(WallV2Actions.getAlertEvents());
        this.store$.dispatch(WallV2Actions.getYoutubeList());
        this.isViewMode$.next(true);
        break;
      case routerSegments.edit:
        this.store$.dispatch(WallV2Actions.getAlertEvents());
        this.store$.dispatch(WallV2Actions.getYoutubeList());
        this.isEditMode$.next(true);
        break;
      case routerSegments.liveView:
        /**
         * For load correct breadcrumb in case of same component
         */
        this.router.navigate([], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
        const liveWall = this.localStorageService.getItem('liveWall');
        if (liveWall) {
          this.store$.dispatch(WallV2Actions.setSelectedWall({ wall: JSON.parse(liveWall) }));
        }
        this.isQuickLiveViewMode$.next(true);
        return;
    }
  }

  public onResizing(ev: ResizeEvent) {
    if (!this.basicLayoutHeight) {
      this.basicLayoutHeight = this.fullScreenContainer.nativeElement.offsetHeight;
    }
    if (!this.basicAlertsHeight) {
      this.basicAlertsHeight = this.alertContainer.nativeElement.offsetHeight;
    }
    const totalHeight = this.basicLayoutHeight + this.basicAlertsHeight;

    let newHeight = this.basicLayoutHeight + (this.basicAlertsHeight - ev.rectangle.height);
    let alertContainerNewHeight = ev.rectangle.height;

    if (totalHeight - newHeight < alertContainerNewHeight) {
      alertContainerNewHeight = totalHeight - newHeight;
    }

    if (alertContainerNewHeight > 286) {
      alertContainerNewHeight = 286;
      newHeight = totalHeight - 286;
    }

    if (alertContainerNewHeight < 52) { // item size + header size
      alertContainerNewHeight = 52;
      newHeight = totalHeight - 52;
    }

    this.renderer2.setStyle(this.alertContainer.nativeElement, 'height', `${alertContainerNewHeight}px`);
    this.renderer2.setStyle(this.fullScreenContainer.nativeElement, 'height', `${newHeight}px`);
    this.alertsIncomingComponent.onResized();
  }

  public onResizeEnd() {
    let alertContainerNewHeight = this.alertContainer.nativeElement.offsetHeight;
    let newHeight = this.fullScreenContainer.nativeElement.offsetHeight;
    const diffAlertRowHeight = alertContainerNewHeight % 26; //item row size
    if (diffAlertRowHeight >= 13) {
      alertContainerNewHeight += diffAlertRowHeight;
      newHeight -= diffAlertRowHeight;
    } else {
      alertContainerNewHeight -= diffAlertRowHeight;
      newHeight += diffAlertRowHeight;
    }

    this.localStorageService.setItem('alertContainerNewHeight', alertContainerNewHeight.toString());
    this.localStorageService.setItem('layoutHeight', alertContainerNewHeight.toString());
    this.renderer2.setStyle(this.alertContainer.nativeElement, 'height', `${alertContainerNewHeight}px`);
    this.renderer2.setStyle(this.fullScreenContainer.nativeElement, 'height', `${newHeight}px`);
    this.alertsIncomingComponent.onResized();

  }

  public fullScreenLoaded() {
    const alertContainerNewHeight = this.localStorageService.getItem('alertContainerNewHeight');
    if (alertContainerNewHeight) {
      this.renderer2.setStyle(this.alertContainer.nativeElement, 'height', `${alertContainerNewHeight}px`);
      this.alertsIncomingComponent.onResized();
    }
    const layoutHeight = this.localStorageService.getItem('layoutHeight');
    if (layoutHeight) {
      this.renderer2.setStyle(this.fullScreenContainer.nativeElement, 'height', `${layoutHeight}px`);
    }
  }

  public changeSet(ev: MatSelectChange) {
    this.store$.dispatch(WallV2Actions.setSelectedSetIndex({ index: ev.value }));
  }

  public removeSelectedEvent(ev: { eventId: string, cameraId: string, tile: number }) {
    this.store$.dispatch(WallV2Actions.removeTileEvent(ev));
  }

  public onEventsChanged(ev: { events: WallV2Model.SelectedEvent[], tile: number }) {
    this.store$.dispatch(WallV2Actions.setEventsToSelectedTile(ev));
  }

  public goToEditWall(wall: WallV2Model.WallMongoDocument | WallV2Model.WallCreateDto) {
    if ('_id' in wall) {
      this.router.navigate([routerSegments.wallV2, routerSegments.edit, wall._id]);
    }
  }

  public deleteWall(wall: WallV2Model.WallMongoDocument | WallV2Model.WallCreateDto) {
    if ('_id' in wall) {
      this.store$.dispatch(SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to delete ${wall.name}?`,
          title: `Delete ${wall.name}`,
          confirm: 'Delete',
          cancel: 'No',
          disableClose: true,
          params: {
            id: wall._id,
            confirmType: WallV2Model.WallConfirmModalType.DeleteWall,
          },
        },
      }));
    }
  }

  public saveQuickView(): void {
    this.store$.dispatch(SharedActions.showConfirmModal({
      options: {
        type: ConfirmDialogType.CONFIRM,
        msg: `Would you like to save this live view as a wall?`,
        title: `Save live view as wall`,
        confirm: 'Yes',
        cancel: 'Cancel',
        disableClose: true,
        params: {
          confirmType: WallV2Model.WallConfirmModalType.SaveQuickLiveViewAsWall,
        },
      },
    }));
  }

  public askQuickLiveViewName() {
    this.dialog.open(SaveQuickLiveViewAsWall, {
        panelClass: 'modal-no-padding',
        width: '300px',
        disableClose: true,
        data: {
          inputLabel: 'Wall name',
        },
      })
      .afterClosed()
      .subscribe((name: string) => {
        if (name) {
          this.store$.dispatch(WallV2Actions.saveQuickView({ name }));
        }
      });
  }

  public ngOnDestroy() {
    if (this.rotationInterval) {
      clearInterval(this.rotationInterval);
      clearInterval(this.rotationTimer);
      this.rotationTime$.next(0);
    }
    this.livekitService.cleanAll();
    this.store$.dispatch(CameraActions.StartSearchQuery({ searchQuery: null }));
  }

  public mute(isMuted: boolean) {
    console.log('mute', isMuted);
    this.store$.dispatch(WallV2Actions.setIsMuted({ isMuted: !isMuted }));
    this.localStorageService.setItem(LocalStorageKeys.WALL_MUTED, !isMuted);
  }

  async fullScreen() {
    const elem = this.fullScreenContainer?.nativeElement;
    if (elem.requestFullscreen) {
      await elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      await elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      await elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      await elem.webkitRequestFullscreen();
    } else {
      if (document.exitFullscreen) {
        if (document.fullscreenElement === null) {
          await document.exitFullscreen();
        }
      } else if (document['mozCancelFullScreen']) {
        await document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        await document['webkitCancelFullScreen']();
      }
    }
  }


  public editQuickWall() {
    const liveWall = this.localStorageService.getItem('liveWall');
    const parsedWall = JsonParseIfValid<WallV2Model.WallCreateDto>(liveWall);
    const selectedCameras: Dictionary<WallV2Model.SelectedCamera> = {};
    if (parsedWall) {
      parsedWall.sets?.[0].tiles.forEach(tile => {
        if (tile?.camera) {
          selectedCameras[tile.camera.cameraId] = tile.camera;
        }
      });
    }
    this.dialog.open(CameraSelectorSublocationsModalComponent, {
        width: '600px',
        height: '80vh',
        panelClass: 'modal-no-padding',
        disableClose: true,
        data: {
          multi: true,
          selectedCameras: selectedCameras,
        },
      })
      .afterClosed()
      .subscribe((selectedCameras: Dictionary<WallV2Model.SelectedCamera>) => {
        const cameras = Object.values(selectedCameras ?? {});
        if (cameras?.length) {
          const layoutsArray = Object.keys(WallV2Model.cameraQuantityLayout);
          let findLayoutIndex = layoutsArray.find(layout => parseInt(layout) >= cameras.length);
          findLayoutIndex = findLayoutIndex ?? '30';
          cameras.length = parseInt(findLayoutIndex);
          const defaultWallCopy: WallV2Model.WallCreateDto = {
            ...WallV2Model.defaultWallCreate,
            sets: [
              {
                name: 'Set 1',
                duration: 0,
                layout: WallV2Model.cameraQuantityLayout[findLayoutIndex],
                tiles: cameras.map(camera => {
                  return { ...WallV2Model.defaultSetTile, camera };
                }),
              },
            ],
          };
          this.localStorageService.setItem('liveWall', JSON.stringify(defaultWallCopy));
          this.store$.dispatch(WallV2Actions.setSelectedWall({ wall: defaultWallCopy }));

        }
      });

  }


  public discardEdit() {
    this.store$.dispatch(SharedActions.showConfirmModal({
      options: {
        type: ConfirmDialogType.CONFIRM,
        msg: `Are you sure you want to discard changes? All unsaved changes will be lost`,
        title: `Discard changes`,
        confirm: 'Discard',
        cancel: 'Cancel',
        disableClose: true,
        params: {
          confirmType: WallV2Model.WallConfirmModalType.DiscardEditWall,
        },
      },
    }));
  }

  public onYoutubeSelected(ev: WallV2Model.OnYoutubeSelectedData) {
    const youtube = ev.youtube;
    this.store$.dispatch(WallV2Actions.setTileYoutube({
      tile: ev.tile, youtube: youtube,
    }));
  }

  public onTileDragged(ev: WallV2Model.OnTileDrag) {
    this.store$.dispatch(WallV2Actions.dragTileToTile({
      fromTile: ev.fromTile, toTile: ev.tile,
    }));
  }

  public onDragYoutubeStart(index: number) {
    this.draggableYoutubeIndex$.next(index);
  }

  public onDragYoutubeEnd() {
    this.draggableYoutubeIndex$.next(null);
  }

  public shareWall(wall: WallV2Model.WallMongoDocument | WallV2Model.WallCreateDto) {
    if ('_id' in wall) {
      this.dialog
        .open(ShareAccessComponent, {
          panelClass: 'thumbnail-dialog-wrapper',
          width: '502px',
          maxHeight: '802px',
          data: {
            title: 'alert',
            name: wall.name,
            entityId: wall._id,
            type: GrantedAccessType.WALL,
          },
        })
        .afterClosed()
        .subscribe(res => {
          console.log(res);
        });
    }
  }

}
