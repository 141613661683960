import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { EdgeCamera } from '../../cameras/camera.model';
import { Observable, take } from 'rxjs';
import { UiLocationSelectItem } from '@models/ui.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { CameraActions } from '@states/camera/camera.action-types';
import { KeyValuePairs } from '../../core/interfaces';
import { UiZoneSelectorDialogResult } from '../ui-kit/ui-zone-selector/ui-zone-selector-dialog/ui-zone-selector-dialog.component';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { SharedActions } from '@states/shared/shared.action-types';
import { AlertEvent } from '@models/alert-events.model';
import { CameraEventSelectionResult, CameraLookup } from '@models/camera.model';
import { ZoneSelectionType } from './location-row-sub/location-row-sub.component';
import { Dictionary } from '@ngrx/entity/src/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WallV2Model } from '@models/wall-v2.model';

@UntilDestroy()
@Component({
  selector: 'camera-picker-sub',
  templateUrl: './camera-picker-sub.component.html',
  styleUrls: ['./camera-picker-sub.component.scss'],
})
/**
 * @deprecated for camera picker use camera-selector-modal
 */
export class CameraPickerSubComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('body') body: ElementRef;
  @Input() narrow: boolean = false;
  /**
   * @deprecated
   * use selectedCamerasV2 instead
   */
  @Input() inputSelectedCameras: EdgeCamera.CameraItem[] | CameraLookup[] = [];
  @Input() multi: boolean;
  @Input() edgeId: string;
  @Input() displayTitle = true;
  @Input() displayActions = true;
  @Input() emitOnSelect = false;
  @Input() inline = false;
  @Input() zoneSelection = false;
  @Input() zoneSelectionType: ZoneSelectionType = ZoneSelectionType.ZONES;
  @Input() edgeOnlySelect = false;
  @Input() atLeastOne = false;
  /**
   * @deprecated
   * alert selection logic will be removed
   */
  @Input() withAlerts: boolean = false;
  @Input() selectedEdges: string[];
  @Input() cameraSelectionLimit: number;
  @Input() selectedCamerasV2: {
    edgeId?: string;
    locationId?: string;
    cameraId?: string;
    events?: WallV2Model.SelectedEvent[]
  }[];
  @Input() fetchMissingObjectData = false;

  @Input() selectOnly = false;
  @Input() selectedCameraId: string;

  @Output() onSave: EventEmitter<EdgeCamera.CameraItem[]> = new EventEmitter<EdgeCamera.CameraItem[]>();
  @Output() onSaveEdges: EventEmitter<string[]> = new EventEmitter<string[]>();

  public selectAllCamerasGroupByLocation$: Observable<UiLocationSelectItem[]>;
  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public selectCamerasLookup$: Observable<Dictionary<EdgeCamera.CameraItem>> = this.store$.pipe(
    select(CameraSelectors.selectCamerasLookup),
  );
  public selectCamerasEventsLookup$: Observable<{ [key: string]: AlertEvent[] }> = this.store$.pipe(
    select(CameraSelectors.selectCamerasEventsArrayLookup),
  );

  public query: string;

  public selectedCameras: EdgeCamera.CameraItem[] | CameraLookup[] = [];
  public selectedCamerasLookup: { [key: string]: boolean } = {};
  public selectedEventsLookup: { [key: string]: boolean } = {};
  public selectedCameraTimeZone: string;

  public selectedLocationsCameraLookup: {
    [key: string]: EdgeCamera.CameraItem[];
  } = {};

  public zonesCache: KeyValuePairs<UiZoneSelectorDialogResult> = {};
  public allSelected: boolean = false;

  public pickerHeight: number;

  constructor(
    private cd: ChangeDetectorRef,
    private store$: Store<AppState>, // private dialogRef: MatDialogRef<CameraSelectorComponent>, // @Inject(MAT_DIALOG_DATA)
  ) // public data: {
  //   selectedCameras: EdgeCamera.CameraItem[];
  //   multi: boolean;
  //   edgeId: string
  // },
  {
  }

  ngAfterViewInit(): void {
    this.pickerHeight = this.body.nativeElement.clientHeight - (this.selectOnly ? 50 : 120);
  }

  public ngOnInit(): void {
    this.selectAllCamerasGroupByLocation$ = this.store$.pipe(select(CameraSelectors.selectAllCamerasGroupByLocation(this.edgeId)));
    this.selectCamerasEventsLookup$
      .subscribe(res => {
      });
    this.selectedCameras = this.inputSelectedCameras;

    /**
     * V2
     */
    if (this.selectedCamerasV2) {
      this.selectCamerasLookup$.pipe(
          untilDestroyed(this),
        )
        .subscribe(cameraLookup => {
          /**
           * Selection could be CAMERA or EVENTS
           */
          const isEvent = this.selectedCamerasV2.some(camera => camera?.events?.length);
          if (isEvent) {
            this.selectedCamerasV2.forEach(tile => {
              tile.events.forEach(event => {
                const camera = cameraLookup[event.cameraId];
                if (camera) {
                  this.selectEvent({ camera, event }, null);
                }
              });
            });
          } else {
            this.selectedCameras = this.selectedCamerasV2.map(camera => {
              return cameraLookup[camera.cameraId];
            });
          }

        });
    }
    this.selectedCameras.forEach(camera => {
      if (!!camera) {
        if (this.selectedLocationsCameraLookup[camera.locationId]) {
          this.selectedLocationsCameraLookup[camera.locationId] = [...this.selectedLocationsCameraLookup[camera.locationId], camera];
        } else {
          this.selectedLocationsCameraLookup[camera.locationId] = [camera];
        }
      }
    });
    this.selectedCameras.map(selectedCamera => {
      if (selectedCamera) {
        if (selectedCamera?.edgeOnly?.cameraId) {
          this.selectedCamerasLookup[selectedCamera.edgeOnly.cameraId] = true;
        } else {
          this.selectedCamerasLookup[selectedCamera.cameraId] = true;
        }
      }
    });


    this.initZoneCache();
    const selectedCamerasQuantity = Object.values(this.selectedLocationsCameraLookup)
      .reduce((sum, value) => sum + value.length, 0);
    this.allSelected = selectedCamerasQuantity !== 0 ? Object.values(this.selectedCamerasLookup).length === selectedCamerasQuantity : false;
  }

  initZoneCache() {
    for(let camera of this.selectedCameras) {
      let key;
      if (!!camera && (!!camera.zones && !!Object.keys(camera?.zones).length || camera.lineCrossing || camera.trafficControl)) {
        if ((<EdgeCamera.CameraItem>camera).edgeOnly) {
          key = (<EdgeCamera.CameraItem>camera).edgeOnly?.cameraId;
        } else {
          key = (<CameraLookup>camera).cameraId;
        }
        this.zonesCache[key] = {
          zones: camera.zones,
          markedIdx: camera.markedIdx,
          lineCrossing: camera.lineCrossing,
          trafficControl: camera.trafficControl,
          exclude: camera.zonesExclude,
        };
      }
    }
  }

  public search() {
    this.store$.dispatch(CameraActions.StartSearchQuery({ searchQuery: this.query }));
  }

  public zoneUpdate(camera: EdgeCamera.CameraItem, cameraSelectedItem: UiLocationSelectItem) {
    if (this.multi) {
      this.selectedCameraTimeZone = cameraSelectedItem.timezone;
      if (this.selectedLocationsCameraLookup[camera.locationId]) {
        const cameraIndexInLocation = this.selectedLocationsCameraLookup[camera.locationId].findIndex(
          item => item?.edgeOnly?.cameraId === camera?.edgeOnly?.cameraId || item?.cameraId === camera?.edgeOnly?.cameraId,
        );

        if (cameraIndexInLocation > -1) {
          this.selectedLocationsCameraLookup[camera.locationId][cameraIndexInLocation] = camera;
        }
      }
    } else {
      // TODO: Right now this one shouldn't be handled, in the future need to add support for this case
      this.selectedLocationsCameraLookup = {
        [camera.locationId]: [camera],
      };
      this.selectedCamerasLookup = {
        [camera.edgeOnly.cameraId]: true,
      };
    }
    if (this.emitOnSelect) {
      this.save();
    }
  }

  public selectCamera(camera: EdgeCamera.CameraItem, cameraSelectedItem: UiLocationSelectItem, selectAllEvents: boolean = true) {
    const checkAndSelectAllEvents = (select: boolean) => {
      if (selectAllEvents && this.withAlerts) {
        this.selectAllEvents(camera, select);
      }
    };
    if (this.multi) {
      this.selectedCameraTimeZone = cameraSelectedItem?.timezone;
      if (this.selectedLocationsCameraLookup[camera.locationId]) {
        const cameraIndexInLocation = this.selectedLocationsCameraLookup[camera.locationId].findIndex(
          item => item?.edgeOnly?.cameraId === camera?.edgeOnly?.cameraId || item?.cameraId === camera?.edgeOnly?.cameraId,
        );

        if (cameraIndexInLocation > -1) {
          this.selectedLocationsCameraLookup[camera.locationId].splice(cameraIndexInLocation, 1);
          checkAndSelectAllEvents(false);
        } else {
          this.selectedLocationsCameraLookup[camera.locationId].push(camera);
          checkAndSelectAllEvents(true);
        }
      } else {
        this.selectedLocationsCameraLookup[camera.locationId] = [camera];
        checkAndSelectAllEvents(true);
      }

      this.selectedCamerasLookup[camera.edgeOnly.cameraId] = !this.selectedCamerasLookup[camera.edgeOnly.cameraId];
    } else {
      this.selectedLocationsCameraLookup = {
        [camera.locationId]: [camera],
      };
      this.selectedCamerasLookup = {
        [camera.edgeOnly.cameraId]: true,
      };
    }
    if (this.emitOnSelect) {
      this.save();
    }
  }

  public selectEvent(selectedResult: CameraEventSelectionResult, cameraSelectedItem: UiLocationSelectItem) {
    const { event, camera } = selectedResult;
    this.selectedEventsLookup = {
      ...this.selectedEventsLookup,
      [event.eventId]: !this.selectedEventsLookup[event.eventId],
    };

    const locationId = camera.locationId;
    /**
     * If camera not selected -> select it
     */
    // if (!this.selectedLocationsCameraLookup[locationId]?.length) {
    if (!this.selectedCamerasLookup[camera.edgeOnly.cameraId]) {
      this.selectCamera(camera, cameraSelectedItem, false);
    }
    const cameraIndexInLocation = this.selectedLocationsCameraLookup[locationId].findIndex(
      item => item?.edgeOnly?.cameraId === camera?.edgeOnly?.cameraId || item?.cameraId === camera?.edgeOnly?.cameraId,
    );

    /**
     * Find index of selected Event
     */
    const alertEventsOfSelectedCamera = this.selectedLocationsCameraLookup[locationId][cameraIndexInLocation]?.alertEvents ?? [];
    const eventIndexInCameraEvents = alertEventsOfSelectedCamera.findIndex(cameraEvent => cameraEvent.eventId === event.eventId);

    if (eventIndexInCameraEvents > -1) {
      /**
       * If event exist -> remove it from existing camera Events
       */
      const alertEvents = [...this.selectedLocationsCameraLookup[locationId][cameraIndexInLocation].alertEvents];
      alertEvents.splice(eventIndexInCameraEvents, 1);

      this.selectedLocationsCameraLookup[locationId][cameraIndexInLocation] = {
        ...this.selectedLocationsCameraLookup[locationId][cameraIndexInLocation],
        alertEvents,
      };
      /**
       * If latest event is removed, deselect camera
       */
      if (!alertEvents.length) {
        this.selectCamera(camera, cameraSelectedItem, false);
      }
    } else {
      /**
       * If event not exist -> add it to existing camera Events
       */
      this.selectedLocationsCameraLookup[locationId][cameraIndexInLocation] = {
        ...this.selectedLocationsCameraLookup[locationId][cameraIndexInLocation],
        alertEvents: [...alertEventsOfSelectedCamera, event],
      };
    }

    // this.selectCamera(camera, cameraSelectedItem, event);
  }

  private selectAllEvents(camera: EdgeCamera.CameraItem, isSelected: boolean) {
    camera.alertEvents.forEach(alertEvent => {
      this.selectedEventsLookup = {
        ...this.selectedEventsLookup,
        [alertEvent.eventId]: isSelected,
      };
    });
  }


  public close() {
    // this.dialogRef.close();
  }

  public save() {
    if (this.edgeOnlySelect) {
      this.onSaveEdges.emit(this.selectedEdges);
      return;
    }
    let selectedCameras = [];
    Object.values(this.selectedLocationsCameraLookup)
      .forEach(cameras => {
        selectedCameras = selectedCameras.concat(cameras);
      });
    if (this.atLeastOne && selectedCameras.length === 0 && !this.withAlerts) {
      this.store$.dispatch(SharedActions.showMessage({ warning: 'Please select at least one camera' }));
      return;
    }
    if (this.withAlerts && Object.keys(this.selectedEventsLookup)?.length === 0) {
      this.store$.dispatch(SharedActions.showMessage({ warning: 'Please select at least one alert' }));
      return;
    }
    this.onSave.emit(selectedCameras);
    // this.dialogRef.close(selectedCameras);
  }

  public selectEdge(selectedEdges: string[]) {
    this.selectedEdges = selectedEdges;
  }

  public selectLocationCameras(cameras: EdgeCamera.CameraItem[], cameraSelectedItem: UiLocationSelectItem) {
    if (this.multi) {
      this.selectedCamerasLookup = {};
      this.selectedEventsLookup = {};
      /**
       * If received empty cameras need to deselect all alerts
       */
      this.selectedLocationsCameraLookup[cameraSelectedItem._id] = [
        ...cameras.map(item => {
          return {
            ...item,
            locationName: cameraSelectedItem.name,
          };
        }),
      ];
      Object.values(this.selectedLocationsCameraLookup)
        .forEach(cameras => {
          cameras.forEach(camera => {
            if (this.withAlerts) {
              this.selectAllEvents(camera, true);
            }
            this.selectedCamerasLookup[camera.edgeOnly.cameraId] = true;
          });
        });
    }
    if (this.emitOnSelect) {
      this.save();
    }
  }

  public reset() {
    this.selectedCameras = [];
    this.selectedCamerasLookup = {};
    this.selectedCameraTimeZone = '';
    this.selectedLocationsCameraLookup = {};
  }

  public selectAllCameras(allCamerasGroupByLocation: UiLocationSelectItem[], selectCamerasEventsLookup: { [key: string]: AlertEvent[] }) {
    this.allSelected = !this.allSelected;
    Object.values(allCamerasGroupByLocation)
      .forEach(location => {
        if (this.allSelected) {
          this.selectedLocationsCameraLookup[location._id] = [
            ...location.cameras.map(camera => {
              let cameraAlertEvents: WallV2Model.SelectedEvent[] = [];
              if (this.withAlerts) {
                cameraAlertEvents = selectCamerasEventsLookup[camera.edgeOnly.cameraId]?.map(event => {
                  return {
                    eventId: event._id,
                    cameraId: event.selectedCamera.cameraId,
                    edgeId: event.selectedCamera.edgeId,
                    locationId: event.selectedCamera.locationId,
                  };
                });
                if (cameraAlertEvents) {
                  this.selectAllEvents({ ...camera, alertEvents: cameraAlertEvents }, true);
                }
              }
              return {
                ...camera,
                alertEvents: cameraAlertEvents ?? [],
                locationName: location.name,
              };
            }),
          ];
        } else {
          this.selectedLocationsCameraLookup = {};
        }

        location.cameras.forEach(camera => {
          this.selectedCamerasLookup[camera.edgeOnly.cameraId] = this.allSelected;
          if (this.withAlerts) {
            const cameraAlertEvents: WallV2Model.SelectedEvent[] = selectCamerasEventsLookup[camera.edgeOnly.cameraId]?.map(event => {
              return {
                eventId: event._id,
                cameraId: event.selectedCamera.cameraId,
                edgeId: event.selectedCamera.edgeId,
                locationId: event.selectedCamera.locationId,
              };
            });
            if (cameraAlertEvents) {
              this.selectAllEvents({ ...camera, alertEvents: cameraAlertEvents }, this.allSelected);
            }
          }
        });
      });
    if (this.emitOnSelect) {
      this.save();
    }
  }

  public getSelectedCameraCount() {
    if (!this.cameraSelectionLimit) {
      return 0;
    }
    return Object.values(this.selectedCamerasLookup)
      .filter(selected => !!selected).length;
  }

  public ngOnDestroy() {
    this.store$.dispatch(CameraActions.StartSearchQuery({ searchQuery: null }));
  }

  public cameraWordTransform(): string {
    const diff = this.cameraSelectionLimit - this.getSelectedCameraCount();
    if (diff === 1) {
      return 'camera';
    } else {
      return 'cameras';
    }
  }


  public trackByLocationId(index: number, item: UiLocationSelectItem) {
    return item._id;
  }
}
