<div class="tile-wrapper h-100"
     (mousemove)="mouseMove($event)" (mouseleave)="mouseLeave()"
     *ngrxLet="selectWallTileSettings$; let selectTileSettings" #tileContainer
>
  <div #hint
       *ngIf="!hintHidden && !this.isEmptyTile && !!this.tile?.camera && viewMode"
       class="hint flex-row start-center gap-8"
  >
    <div>Scroll to zoom in/out</div>
    <div><img ngSrc="assets/animations/scroll.gif" width="50" height="50"></div>
    <div>Click and drag to move</div>
    <div><img ngSrc="assets/animations/drag.gif" width="50" height="50"></div>
  </div>
  <ng-container *ngrxLet="selectEventsLookup$; let eventsLookup">
    <ng-container *ngrxLet="selectCamerasLookup$;let cameraLookup">
      <ng-container *ngrxLet="selectYoutubeMap$; let youtubeMap">
        <div class="tile-inner h-100" #fullScreenContainer>
          @if (viewMode) {
            <ng-container *ngrxLet="selectAlertByTile$;let alertByTile">

              <div class="view-tile-inner h-100 w-100"
                   [class.alert]="isAlertTile"
                   [class.empty]="isEmptyTile"
              >
                @if (selectTileSettings?.showTileNumber && !isAlertTile) {
                  <div class="tile-index flex-row center-center"
                       [class.camera-tile]="!!tile.camera || !!tile.youtubeId"
                       [class.empty-tile]="isEmptyTile"
                  >
                    {{ index + 1 }}
                  </div>
                }

                @if (isEmptyTile) {
                  <div class="empty-tile-wrapper h-100 w-100 flex-col center-center">
                    <span class="ui-icon-lumana-logo"></span>
                  </div>
                } @else if (!!tile?.camera) {
                  <div class="h-100 w-100 p-absolute">
                    <ng-container *ngrxLet="locationName(alertByTile?.selectedCamera?.locationId); let locationName">
                      <webrtc-player
                        [showControls]="true"
                        [isWall]="true"
                        [extraRight]="isSelected"
                        #player
                        [isExternallyManaged]="true"
                        [isDragAllowed]="false"
                        [class.hidden]="!tile?.camera?.cameraId"
                        [class.on-top]="player?.inZoom()"
                        class="w-100 h-100"
                        [isRespectRatio]="false"
                        [autostart]="true"
                        [edgeId]="tile.camera?.edgeId"
                        [cameraId]="tile.camera?.cameraId"
                        [locationId]="tile.camera?.locationId"
                        [allowZoom]="true"
                        [enableQualitySelection]="false"
                        (mousedown)="player.dragStart($event)"
                        (mouseup)="player.dragUp()"
                        (mousemove)="player.drag($event)"
                        [zoomPreview]="false"
                        [showTime]="selectTileSettings.showTimestamp"
                        [showCameraName]="selectTileSettings.showCameraName"
                        [showCameraStatus]="selectTileSettings.showCameraStatus"
                        [cameraName]="cameraLookup[tile.camera?.cameraId]?.edgeOnly?.name"
                        [permissionCheck]="checkPermissions"
                        (clickTakeSnapshot)="player.takeSnapshot(cameraLookup[tile.camera?.cameraId]?.edgeOnly?.name, locationName, now)"
                        (clickFullScreen)="fullScreen()"
                        (closeTile)="onTileClosed.emit()"

                      >
                      </webrtc-player>
                    </ng-container>
                  </div>


                } @else if (isAlertTile) {

                  @if (alertByTile) {
                    <ng-container>
                      <ng-container>
                        <ng-container>

                          <wall-alert-tile [index]="index" [tileAlert]="alertByTile"
                                           [alertSettings]="selectWallAlertsSettings$|ngrxPush"
                                           [allCamerasMap]="selectCamerasLookup$|ngrxPush"
                                           [wallTileSettings]="selectTileSettings"
                                           [isOpened]="isSelected"
                                           [checkPermissions]="checkPermissions"
                                           (onFullscreenClicked)="fullScreen()"
                                           (onTileClosed)="onTileClosed.emit()"
                          >

                          </wall-alert-tile>
                        </ng-container>
                      </ng-container>
                    </ng-container>

                  } @else {

                    @if (selectTileSettings?.showTileNumber) {
                      <div class="tile-index flex-row center-center alert-tile">
                        {{ index + 1 }}
                      </div>
                    }
                    <div class="alert-wrapper h-100 w-100 flex-col center-center gap-16">
                      <span class="ui-icon-notifications-no-stroke"></span>
                      <tile-event-list [style.height.px]="(tileContainer.clientHeight/2)-20"
                                       [selectedEvents]="tile?.events|uniqueSelectedEvents"
                                       class="hover"
                                       [eventsMap]="eventsLookup"
                                       [isAllOrganizationEvents]="tile.allEvents"
                                       [allowDelete]="false"
                                       (onSelectedEventRemoved)="removeSelectedEvent($event)"
                                       (onSelectedEventsChanged)="onAlertsChanged.emit($event)"
                                       (onCleanAll)="onCleanTile.emit()"
                      >

                      </tile-event-list>
                    </div>


                  }
                } @else if (!!tile.youtubeId) {
                  @if (!(forceHideTile$|async)) {
                    <div class="h-100 w-100">
                      <iframe class="w-100 h-100"
                              [src]="(youtubeMap[tile.youtubeId]?.url+'?autoplay=1&mute=1')|safeUrl"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerpolicy="strict-origin-when-cross-origin"
                              allowfullscreen
                      ></iframe>
                    </div>
                  }
                }


              </div>

            </ng-container>
          } @else {
            <!--          EDIT MODE-->
            <div class="edit-tile-inner h-100 w-100"
                 [class.alert]="isAlertTile"
                 [class.empty]="isEmptyTile"
                 [class.isDragHover]="isDragHover"
            >

              @if (selectTileSettings?.showTileNumber) {
                <div class="tile-index flex-row center-center "
                     [class.alert-tile]="isAlertTile"
                     [class.camera-tile]="!!tile.camera || !!tile.youtubeId"
                     [class.empty-tile]="isEmptyTile"
                >
                  {{ index + 1 }}
                </div>
              }


              <!--EVENT TILE-->
              @if (isAlertTile) {
                <div class="alert-wrapper h-100 flex-col center-center gap-16">
                  <span class="ui-icon-delete icon-hover hover" (click)="onCleanTile.emit()"></span>
                  <span class="ui-icon-notifications-no-stroke"></span>
                  <tile-event-list [style.height.px]="(tileContainer.clientHeight/2)-20"
                                   [selectedEvents]="tile?.events|uniqueSelectedEvents"
                                   class="hover"
                                   [eventsMap]="eventsLookup"
                                   [isAllOrganizationEvents]="tile.allEvents"
                                   [allowDelete]="true"
                                   (onSelectedEventRemoved)="removeSelectedEvent($event)"
                                   (onSelectedEventsChanged)="onAlertsChanged.emit($event)"
                                   (onCleanAll)="onCleanTile.emit()"
                  >

                  </tile-event-list>
                </div>
              } @else if (!!tile?.camera) {
                <div class="h-100 flex-col center-center gap-16 camera-wrapper">
                  <span class="ui-icon-delete icon-hover hover" (click)="onCleanTile.emit()"></span>
                  <div class="h-100 w-100 p-absolute">
                    <ui-camera-status-snapshot [cameraId]="tile.camera.cameraId"
                                               [edgeId]="tile.camera.edgeId"
                                               [cameraName]="cameraLookup[tile.camera.cameraId]?.edgeOnly.name"
                                               [showName]="selectTileSettings.showCameraName"
                                               [showStatus]="selectTileSettings.showCameraStatus"
                                               class="h-100 w-100"
                    >

                    </ui-camera-status-snapshot>
                  </div>

                </div>
              } @else if (!!tile.youtubeId) {
                <div class="h-100 flex-col center-center gap-16 camera-wrapper">
                  <span class="ui-icon-delete icon-hover hover" (click)="onCleanTile.emit()"></span>
                  <img [src]="youtubeMap[tile.youtubeId]?.url|youtubePreview" width="100%" height="100%">
                </div>
              }
            </div>
          }


        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
