<ng-container *ngrxLet="selectGrantedList$;let grantedList">
  <ng-container *ngrxLet="selectSelectedGrantedAccess$;let selectedGrantedAccess">
    <div>
      <div *ngIf="showHeader" fxLayout="row" fxLayoutAlign="space-between center" class="header">
        <span>Share {{ isModal ? data.title : '' }}</span>
        <mat-icon mat-dialog-close>close</mat-icon>
      </div>
      <div *ngIf="{activeTab:activeTab$|async} as conditions" fxLayout="column" class="body">
        <div class="tabs flex-row pl-24">
          <div class="tab c-p" [class.active]="conditions.activeTab === 0" (click)="setActiveTab(0)">
            Create link
          </div>
          <div *ngIf="showHeader"
               class="tab c-p"
               [class.disabled]="!grantedList?.length"
               [class.active]="conditions.activeTab === 1"
               (click)="setActiveTab(1)"
          >
            Existing links <span class="count-badge">{{ grantedList?.length }}</span>
          </div>
        </div>
        <div>
          <ng-container *ngIf="conditions.activeTab === 0" [ngTemplateOutlet]="shareTab"></ng-container>
          <ng-container *ngIf="conditions.activeTab === 1" [ngTemplateOutlet]="invitedTab"></ng-container>
        </div>
      </div>
      <div *ngIf="showHeader" class="footer" fxLayout="row" fxLayoutAlign="end center">
        <ui-button
          [disabled]="sharedForm.get('isPassword').value && (!sharedForm.get('password')?.value)"
          type="default"
          text="Done"
          (clickAction)="close()"
        ></ui-button>
      </div>
    </div>

    <ng-template #shareTab>
      <div class="share-tab" [formGroup]="sharedForm">
        <div fxLayout="column" class="ui-input-container-no-icon">
          <label>Name</label>
          <input [class.invalid]="sharedForm.get('name').invalid && sharedForm.get('name').touched"
                 class="with-label ui-input"
                 placeholder="Name"
                 formControlName="name"
          />
        </div>
        <hr class="hr">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-15">
          <span class="label">Access to {{ data.type === GrantedAccessType.LIVE_VIEW ? 'camera' : 'video' }} for</span>
          <mat-select
            class="ui-selector w-50"
            formControlName="accessPeriod"
          >
            <mat-option *ngFor="let accessPeriod of archiveAccessPeriod" [value]="accessPeriod.value">
              {{ accessPeriod.name }}
            </mat-option>
          </mat-select>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="start center"
             class="toggle-container"
             *ngIf="data.type === GrantedAccessType.ARCHIVE || data.type === GrantedAccessType.ALERT"
        >
          <ui-toggle formControlName="allowDownload"></ui-toggle>
          <span class="label">Allow to download</span>
          <span class="ui-icon-info"
                matTooltip="Allow downloading of the shared archive over the link you are creating"
          ></span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="toggle-container">
          <ui-toggle formControlName="isPassword"></ui-toggle>
          <span class="label">Password</span>
          <span class="ml-4 ui-icon-info" matTooltip="Protect the link with a password"></span>
        </div>
        <div *ngIf="sharedForm.get('isPassword')?.value"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             class="ui-input-container-no-icon double-input-container"
        >
          <input [type]="isPasswordVisible?'text':'password'"
                 placeholder="Password"
                 formControlName="password"
                 class="ui-input"
          />
          <input [type]="isPasswordVisible?'text':'password'"
                 [class.invalid]="(sharedForm.get('password')?.value !== sharedForm.get('confirmPassword')?.value) &&
                 sharedForm.get('confirmPassword')?.touched"
                 placeholder="Confirm password"
                 class="ui-input"
                 formControlName="confirmPassword"
                 style="margin-left:8px; margin-right: 8px"
          />

          <span *ngIf="!isPasswordVisible"
                class="ui-icon-hide c-p icon-hover"
                (click)="isPasswordVisible = !isPasswordVisible"
          ></span>
          <span *ngIf="isPasswordVisible"
                class="ui-icon-eye c-p icon-hover"
                (click)="isPasswordVisible = !isPasswordVisible"
          ></span>

        </div>
        <div *ngIf="showHeader" class="share-btn-container" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="share-btn-wrapper">
            <ui-progress-button
              [disabled]="!!selectedGrantedAccess.url ||
            (sharedForm.invalid && sharedForm.touched) ||
            (sharedForm.get('password')?.value !== sharedForm.get('confirmPassword')?.value) ||
            sharedForm.get('isPassword').value && !sharedForm.get('password').value"
              [loading]="selectIsSaving$ | ngrxPush"
              text1="Create link"
              text2="Create link"
              (clickAction)="createGrantAccess()"
            >
            </ui-progress-button>
          </div>
          <span *ngIf="!!selectedGrantedAccess.url" class="generate-link" (click)="refresh()">Generate new link</span>
        </div>
        <ng-container *ngIf="showHeader">
          <hr class="hr">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between end"
            class="ui-input-container-no-icon"
          >
            <div fxLayout="column" style="width: 100%">
              <label class="mb-5">Video shared link</label>
              <input disabled [value]="selectedGrantedAccess.url??''"/>
            </div>
            <button [disabled]="!selectedGrantedAccess.url"
                    [class.disabled]="!selectedGrantedAccess.url"
                    class="copy-btn c-p"
                    [cdkCopyToClipboard]="selectedGrantedAccess.url??''"
                    (click)="showCopyMsg()"
            >
              <span *ngIf="!copied" class="ui-icon-copy1 "></span>
              <span *ngIf="copied" class="ui-icon-checkmark  copied"></span>
            </button>
            <button [disabled]="!selectedGrantedAccess.url"
                    [class.disabled]="!selectedGrantedAccess.url"
                    class="copy-btn  c-p"
                    (click)="showPeople(selectedGrantedAccess)"
            >
              <span class="ui-icon-share "></span>
            </button>
          </div>
        </ng-container>

      </div>
    </ng-template>

    <ng-template #invitedTab>
      <div style="max-height: 452px; overflow-y: auto;">
        <div class="invite-tab"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             *ngFor="let invite of grantedList; let i = index"
        >
          <div fxLayout="column">
            <span class="invite-title">{{ invite.name?.length === 0 ? 'No link name' : invite.name }}</span>
            <div class="invite-expired" *ngIf="invite.expired_at !==-1">
              <ng-container *ngIf="(invite.expired_at|timeFromNow) > 0">
                Until
                <ui-user-settings-date [date]="invite.expired_at * 1000"></ui-user-settings-date>
              </ng-container>
              <ng-container *ngIf="(invite.expired_at|timeFromNow) < 0">
                <span class="expired">Expired</span>
              </ng-container>

            </div>
            <span class="invite-expired" *ngIf="invite.expired_at ===-1">Lifetime</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center">
            <span class="menu-item ui-icon-copy1"
                  *ngIf="copiedIndex !== i"
                  [cdkCopyToClipboard]="invite.url"
                  (click)="copyIndex(i)"
            ></span>
            <span class="menu-item ui-icon-checkmark"
                  *ngIf="copiedIndex === i"
            ></span>
            <span class="menu-item ui-icon-forward-3" (click)="showPeople(invite)"></span>
            <span class="menu-item ui-icon-more-menu"
                  *ngIf="invite.expired_at===-1 || (invite.expired_at|timeFromNow) > 0"
                  [matMenuTriggerFor]="action"
            ></span>
          </div>

          <mat-menu #action="matMenu" xPosition="before">
            <span *ngIf="invite.expired_at!==-1" mat-menu-item [matMenuTriggerFor]="extend">
              <span class="flex-row space-between-center">
                              Extend
                <span class="ui-icon-expand-arrow"></span>
              </span>
            </span>
            <ng-container>
              <button *rbacOrgAllow="[permissions.ArchiveShareRevoke]" mat-menu-item (click)="revokeAccess(invite._id)">
                Revoke
              </button>
            </ng-container>
            <button *ngIf="invite.isPassword" mat-menu-item (click)="showPassword(invite.password)">
              <div class="flex-row">
                <span class="ui-icon-eye" style="margin-right: 6px"></span>
                <span>Password</span>
              </div>
            </button>
          </mat-menu>

          <mat-menu #extend="matMenu" xPosition="before">
            <div class="second-menu-title">Extend for additional</div>
            @for (accessPeriod of archiveAccessPeriod; track accessPeriod) {
              <button mat-menu-item (click)="extendAccess(invite._id,accessPeriod.value)">
                {{ accessPeriod.name }}
              </button>
            }
          </mat-menu>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
