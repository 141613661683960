import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, exhaustMap, map, of, share, switchMap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { AlertsV2StoreActions } from '@states/alerts-v2/alerts-v2.action-types';
import { AlertEventsService } from '../../development/alert-events.service';
import { ActionType, AlertCategory, AlertsV2People, AlertsV2Plates, AlertV2SelectedFlow, AlertV2SendModel, DoorSystemType, IdentificationType, IntegrationsType, SafetyType, StatusType, TrackingType } from '@models/alerts-v2.model';
import { SessionDataAction } from '@enums/session-data.enum';
import { AlertEventTrafficControl } from '@models/alert-events.model';
import * as _ from 'lodash';
import { CameraLookup } from '@models/camera.model';
import { EdgeCamera } from '../../cameras/camera.model';
import { IntegrationsModel } from '@models/integrations.model';
import { WebhookService } from '../../services/webhook.service';
import Providers = IntegrationsModel.Providers;

@Injectable()
export class AlertsV2Effect {

  public saveAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsV2StoreActions.saveAlert),
      exhaustMap(() => [SharedActions.setIsSaving({ isSaving: true }), AlertsV2StoreActions.sendAlert()]),
    ),
  );

  validateLineCrossing(cameras: CameraLookup[]): boolean {
    for(let camera of cameras) {
      if (!camera?.lineCrossing || !camera?.lineCrossing?.p1 || !camera?.lineCrossing?.p2) {
        return false;
      }
    }
    return true;
  }

  validateMultipleLineCrossing(cameras: EdgeCamera.CameraItem[]): boolean {
    for(let camera of cameras) {
      if (!camera?.lineCrossing) {
        return false;
      }
      for(let line of (camera?.lineCrossing as AlertEventTrafficControl).lines) {
        if (!line.p1 || !line.p2) {
          return false;
        }
      }
    }
    return true;
  }

  validateSafety(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case SafetyType.Door:
        if (!value?.doors?.length) {
          valid = false;
        }
        break;
      case SafetyType.SpeedLimit:
        if (!value?.objects?.length || !value?.camera[0]?.trafficControl || !value?.duration) {
          valid = false;
        }
        break;
      case SafetyType.ZoneProtection:
        if (!value?.objects?.length || !value?.duration && value?.duration !== 0) {
          valid = false;
        }
        break;
      case SafetyType.Trespassing:
        if (!value?.objects?.length || !this.validateMultipleLineCrossing(value?.camera)) {
          valid = false;
        }
        break;
      case SafetyType.Tailgating:
        if (!value?.objects?.length || !this.validateLineCrossing(value?.camera)) {
          valid = false;
        }
        break;
      case SafetyType.Proximity:
        if (value?.objects?.length < 2 || !value?.duration && value?.duration !== 0) {
          valid = false;
        }
        break;
      default:
        break;

    }
    return valid;
  }

  validateIntegrations(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case IntegrationsType.EventTag:
        if (!value?.eventTagId) {
          valid = false;
        }
        break;
      case IntegrationsType.EventTagPosValidation:
        if (!value?.eventTagId || value?.objects?.length === 0) {
          valid = false;
        }
        break;
      case IntegrationsType.EventTagTailGating:
        if (value?.objects?.length === 0) {
          valid = false;
        }
        break;
      default:
        break;

    }
    return valid;
  }

  validateTracking(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case TrackingType.Loitering:
        if (value?.objects?.length === 0 || (!value?.duration && value.duration !== 0) || (!value?.durationUnit && value.durationUnit !== 0)) {
          valid = false;
        }
        break;
      case TrackingType.LineCrossing:
        if (value?.objects?.length === 0 || !this.validateMultipleLineCrossing(value?.camera)) {
          valid = false;
        }
        break;
      case TrackingType.Occupancy:
        if (value?.amount?.length === 0) {
          valid = false;
        }
        break;
      default:
        if (value?.objects?.length === 0) {
          valid = false;
        }
        break;

    }
    return valid;
  }

  validateIdentification(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case IdentificationType.Container:
        if (!value?.containers?.list?.length) {
          valid = false;
        }
        break;
      case IdentificationType.LPR:
        const plates: AlertsV2Plates = value?.plates;
        if (!plates?.list?.length) {
          valid = false;
        }
        break;
      case IdentificationType.FaceDetection:
        const people: AlertsV2People = value?.people;
        if (!people?.list?.length) {
          valid = false;
        }
        break;
      default:
        break;

    }
    return valid;
  }

  validateStatus(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case StatusType.Edge:
        if (!value.edges?.length || !value?.duration && value?.duration !== 0) {
          valid = false;
        }
        break;
      default:
        if (!value?.duration && value?.duration !== 0) {
          valid = false;
        }
        break;
    }
    return valid;
  }

  public alertValid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[
        AlertsV2StoreActions.setName,
        AlertsV2StoreActions.setSelectedFlow,
        AlertsV2StoreActions.setActions,
        AlertsV2StoreActions.setSettings,
      ]),
      withLatestFrom(this.store$.pipe(select(state => state.alertsV2State))),
      switchMap(([, alertsV2State]) => {
        let valid = true;
        let nameValid = true;
        let flowValid = true;
        let actionsValid = true;

        // if (!alertsV2State?.name) {
        //   nameValid = false;
        // }

        // Flow validations
        const flow = alertsV2State.selectedFlow;

        switch (flow.category) {
          case AlertCategory.Tracking:
            flowValid = this.validateTracking(flow);
            break;
          case AlertCategory.Safety:
            flowValid = this.validateSafety(flow);
            break;
          case AlertCategory.Identification:
            flowValid = this.validateIdentification(flow);
            break;
          case AlertCategory.Status:
            flowValid = this.validateStatus(flow);
            break;
          case AlertCategory.Integrations:
            flowValid = this.validateIntegrations(flow);
            break;

        }

        // Action validation

        const actions = alertsV2State.actions;

        for(let action of actions) {
          if (action.actionType !== null) {
            switch (+action?.actionType) {
              case ActionType.NOTIFY:
                if (!action?.formValue?.notifications?.orgUsers?.length && !action?.formValue?.notifications?.manualUsers?.length) {
                  actionsValid = false;
                }
                break;
              case ActionType.GPIO:
                if (!action?.formValue['gpio'] || action?.formValue['gpio'] === 0) {
                  actionsValid = false;
                }
                if (!action?.formValue['duration'] || action?.formValue['duration'] === 0) {
                  actionsValid = false;
                }
                if (!action?.formValue['level'] || action?.formValue['level'] === 0) {
                  actionsValid = false;
                }
                break;
              case ActionType.SEND_MESSAGE:
                if (
                  !!action?.formValue &&
                  !action?.formValue['httpRequest']['address']
                ) {
                  actionsValid = false;
                }
                break;
              case ActionType.INTEGRATION_PROVIDER_ACTION:
                const provider: IntegrationsModel.Providers = action?.formValue?.provider;
                switch (provider) {
                  case Providers.Slack:
                    if (!action.formValue?.params?.channel) {
                      actionsValid = false;
                    }
                    break;
                }
                break;
            }
          }
        }

        if (flow.category === AlertCategory.Status && flow.flowType === StatusType.Edge ||
          flow.category === AlertCategory.Integrations && flow.flowType === IntegrationsType.EventTagTailGating
        ) {
        } else {
          if (!flow?.formValue?.camera?.[0]?.cameraId) {
            flowValid = false;
          }
        }
        if (alertsV2State.valid !== (flowValid && nameValid && actionsValid)) {
          return [AlertsV2StoreActions.setValid({ valid: flowValid && nameValid && actionsValid })];
        }
        return [SharedActions.doNothing()];
      }),
    ));

  public sendAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsV2StoreActions.sendAlert),
      withLatestFrom(this.store$.pipe(select(state => state.alertsV2State)), this.store$.pipe(select(state => state.alertEventsState))),
      mergeMap(([, alertsV2State, alertEventsState]) => {

        // Validate
        const send: AlertV2SendModel = {
          name: !!alertsV2State?.name ? alertsV2State?.name : 'Untitled alert',
          actions: alertsV2State.actions,
          selectedFlow: alertsV2State.selectedFlow,
          settings: alertsV2State.settings,
        };

        // handle event tag tailgating
        if (alertsV2State?.selectedFlow.category === AlertCategory.Integrations && alertsV2State?.selectedFlow?.flowType === IntegrationsType.EventTagTailGating) {

          const cameras = alertsV2State?.selectedFlow?.formValue?.doors?.map(door => {
            return {
              ...door?.cameras?.[0],
              systemType: door?.sourceType ?? DoorSystemType.Genea,
              doorDbId: door?._id,
              doorId: door?.doorId,
            };
          });
          const selectedFlow = _.cloneDeep(alertsV2State?.selectedEvent?._id ? alertsV2State?.selectedEvent?.selectedFlow : alertsV2State?.selectedFlow);
          if (alertsV2State?.selectedEvent?._id) {
            for(let newCamera of cameras) {
              const camera = selectedFlow?.formValue?.camera?.find(c => c?.doorDbId === newCamera?.doorDbId);
              if (!!camera) {
                newCamera.eventTagId = camera?.eventTagId;
              }
            }
          }
          selectedFlow.formValue.camera = cameras;
          send.selectedFlow = selectedFlow;
        }

        const operation = alertsV2State?.selectedEvent?._id ?
          this.alertEventsService.updateAlertEventsV2(alertsV2State?.selectedEvent?._id, send) :
          this.alertEventsService.createAlertV2(send);

        return operation.pipe(
          mergeMap(res => {
            const actions = [];
            if (res?.length) {
              for(let result of res) {
                actions.push(SharedActions.subscribeToSessionStatus({
                  token: result?.token?.session,
                  sessionDataAction: SessionDataAction.sendAlert,
                  params: {
                    msTimeout: 40000,
                  },
                }));
              }
            }

            return [
              SharedActions.showMessage({
                success: `Alert has been ${alertsV2State?.selectedEvent?._id ? 'updated' : 'created'}`,
              }),
              SharedActions.setIsSaving({ isSaving: false }),
              AlertsV2StoreActions.saveAlertSuccess(),
              ...actions,
            ];
          }),
          catchError(err => {
            return [
              SharedActions.showMessage({
                error: err.error?.message ?? 'Error creating alert',
              }),
              SharedActions.setIsSaving({ isSaving: false }),
            ];
          }),
        );
      }),
      share(),
    ));


  public alertChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[
        AlertsV2StoreActions.setName,
        AlertsV2StoreActions.setSelectedFlow,
        AlertsV2StoreActions.setActions,
        AlertsV2StoreActions.setSettings,
      ]),
      withLatestFrom(this.store$.pipe(select(state => state.alertsV2State)), this.store$.pipe(select(state => state.alertEventsState))),
      switchMap(([, alertsV2State, alertEventsState]) => {
        const selectedEvent = _.cloneDeep(alertsV2State.selectedEvent);
        if (!selectedEvent) {
          return [AlertsV2StoreActions.setChanged({ changed: false })];
        }
        if (selectedEvent?.selectedFlow?.formValue?.schedule === undefined) {
          selectedEvent.selectedFlow.formValue.schedule = undefined;
        }
        for(let action of selectedEvent.actions) {
          if (action.actionType === null) {
            action.formValue = undefined;
          }
        }
        // const eventId = selectedEvent?._id;
        // const origEvent = alertEventsState.alertEvents.find(e => e._id === eventId) as unknown as EventV2Document;
        if (!selectedEvent || (_.isEqual(selectedEvent.name, alertsV2State.name) &&
          _.isEqual(selectedEvent.selectedFlow, alertsV2State.selectedFlow) &&
          _.isEqual(selectedEvent.settings, alertsV2State.settings) &&
          _.isEqual(selectedEvent.actions, alertsV2State.actions))
        ) {
          return [AlertsV2StoreActions.setChanged({ changed: false })];
        }
        return [AlertsV2StoreActions.setChanged({ changed: true })];
      })));

  getPromptConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsV2StoreActions.getPromptConfig),
      withLatestFrom(this.store$.pipe(select(state => state.alertsV2State))),
      switchMap(([, alertsV2State]) => {
        const selectedFlow = _.cloneDeep(alertsV2State.selectedFlow);
        return this.alertEventsService.getPromptConfig(selectedFlow.flowType, selectedFlow.category)
          .pipe(
            map(promptConfig => AlertsV2StoreActions.getPromptConfigSuccess({ promptConfig })),
            catchError(error => of(AlertsV2StoreActions.getPromptConfigFailure({ error }))),
          );
      }),
    ),
  );

  public getOrganizationWebhooks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsV2StoreActions.getWebhooks),
      switchMap(() => {
        return this.webhookService.getOrganizationWebhooks()
          .pipe(
            switchMap(res => {
              return [
                AlertsV2StoreActions.getWebhooksSuccess({ webhooks: res }),
              ];
            }),
            catchError(() => {
              return of(
                AlertsV2StoreActions.getWebhooksFail(),
              );
            }),
          );
      }),
    ),
  );


  public pressSave$ = createEffect(() => this.actions$.pipe(ofType(AlertsV2StoreActions.pressSave), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private alertEventsService: AlertEventsService,
              private webhookService: WebhookService) {
  }
}
