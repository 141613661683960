import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CameraThumbnailsData } from '../../../cameras/camera-thumbnails/camera-thumbnails.model';

import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';
import { KeyValuePairs } from '../../../core/interfaces';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, filter, Observable, of, take, timeout } from 'rxjs';
import { MultiPlaybackData, MultiPlaybackMove } from '@models/multi-playback.model';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EdgeCamera } from '../../../cameras/camera.model';
import { ThumbnailHistogramColor } from '@consts/thumbnail.const';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { MultiPlaybackEffect } from '@effects/multi-playback.effect';
import * as moment from 'moment/moment';
import { PreloaderColor, ThumbnailImageSelectorType } from '@enums/shared.enum';
import { formatDate } from '@angular/common';
import { StateChange } from 'ng-lazyload-image';
import { MediaCacheService } from '../../media-cache/media-cache.service';
import { ThumbnailsSelectors } from '@states/thumbnails/thumbnails.selector-types';

const LOOP_FREQUENCY = 1000;

@UntilDestroy()
@Component({
  selector: 'thumbnail-image',
  templateUrl: './thumbnail-image.component.html',
  styleUrls: ['./thumbnail-image.component.scss'],
})
export class ThumbnailImageComponent implements OnInit, OnChanges {
  public ThumbnailHistogramColor = ThumbnailHistogramColor;
  public defaultImage = 'assets/thumb_placeholder.png';
  public PreloaderColor = PreloaderColor;

  public timeSubject = new BehaviorSubject<string>(null);
  public time$ = this.timeSubject.asObservable();

  public selectData$: Observable<MultiPlaybackData> = this.store$.select(MultiPlaybackSelectors.selectData)
    .pipe(untilDestroyed(this));
  public selectMove$: Observable<MultiPlaybackMove> = this.store$.select(MultiPlaybackSelectors.selectMove)
    .pipe(untilDestroyed(this));
  public selectedCameras$: Observable<EdgeCamera.CameraItem[]> = this.store$.select(MultiPlaybackSelectors.selectSelectedCameras)
    .pipe(untilDestroyed(this));
  public selectCameraEvents$: Observable<number[][]> = this.store$.select(MultiPlaybackSelectors.selectCameraEvents)
    .pipe(untilDestroyed(this));
  public selectPositions$: Observable<number[]> = this.store$.select(MultiPlaybackSelectors.selectPositions);

  public thumbnailImageSelectorTypes = ThumbnailImageSelectorType;

  private selectedCameras: EdgeCamera.CameraItem[] = [];

  private eventsRaw: KeyValuePairs<number[]> = {};
  currentTs: number;

  @Output() select = new EventEmitter<ThumbnailImageSelectorType>(null);
  @Output() delete = new EventEmitter<ThumbnailImageSelectorType>(null);

  @Input() timestamp: number;
  @Input() relative: boolean;
  @Input() percentage: number;
  @Input() cameraId: string;
  @Input() edgeId: string;
  @Input() events: number[];
  @Input() defaultThumbnail: string;
  @Input() timezone: string;
  @Input() offsetResInDurations: number = 60;
  @Input() startTime: number;
  @Input() endTime: number;
  @Input() cameraIdx: number;
  @Input() index: number;
  @Input() withAlerts: boolean = false;
  @Input() imageSection: boolean = true;

  @Input() loop = false;

  @Input() fixedRange = false;
  @Input() useSharedToken: boolean = false;
  loopInterval;
  loopMask = false;

  cameraName: string;

  loading = false;
  imageLoader = true;
  error = false;

  thumbnailsUris: string[];
  first: string;
  @Input() base: number;
  base24: number;
  @Input() duration: number;


  offline = false;
  baseUrl: string;
  public img: string = '';

  swap = false;
  _reloadImage = true;

  timeformat = 'h:mm:ss a';

  public formatDate = formatDate;

  thumbInit = false;

  displayTime: number;

  constructor(
    private store$: Store,
    private multiPlaybackEffect: MultiPlaybackEffect,
    private mediaCacheService: MediaCacheService,
    private cameraThumbnailsService: CamerasThumbnailsService) {
  }

  public get cameraEvents(): number[] {
    return this.events;
  }

  public get time() {

    return this.displayTime ? formatDate(this.cameraThumbnailsService.convertTsToZone(this.displayTime, this.timezone!, moment.tz.guess()), this.timeformat, 'en-US') : '';
  }

  public get timezoneAbbreviation() {
    const cameraZone = moment()
      .tz(this.timezone ?? moment.tz.guess())
      .format('z');
    return cameraZone;
  }

  async initMultiple(swap: boolean = false) {
    if (!!this.selectedCameras[this.cameraIdx]) {
      this.cameraName = this.selectedCameras[this.cameraIdx]?.edgeOnly?.name;
      this.cameraId = this.selectedCameras[this.cameraIdx]?.edgeOnly?.cameraId ?? this.selectedCameras[this.cameraIdx]?.cameraId;
      this.edgeId = this.selectedCameras[this.cameraIdx]?.edgeId;
      this.baseUrl = `${environment.thumbnailsUrl}/thumbnails/${this.edgeId}/${this.cameraId}`;
      setTimeout(async () => {
        this.img = await this._img();
      }, 500);
      // this.initThumbnails(swap);
    } else {
      delete this.cameraId;
      delete this.edgeId;
      delete this.cameraName;
      delete this.events;
      this.eventsRaw = {};
      this.baseUrl = '';
    }
  }

  ngOnInit(): void {
    this.selectData$.subscribe((data: MultiPlaybackData) => {
      const rangeChange = !this.fixedRange && (data.startTime !== this.startTime || data.endTime !== this.endTime);
      this.timezone = data.timezone;
      this.offsetResInDurations = data.offsetResInDurations;
      this.startTime = data.startTime;
      this.endTime = data.endTime;
      this.base = data.startTime;
      this.duration = data.duration;
      this.timestamp = data.startTime;
    });

    this.selectMove$.subscribe(async (move: MultiPlaybackMove) => {
      if (!!this.edgeId && !!this.cameraId && !!move.timestamp && (move.percentage || move.percentage === 0)) {
        this.loopMask = true;
        this.timestamp = Math.floor(move.timestamp);
        this.timeSubject.next(this.time);
        this.percentage = Math.max(move.percentage, 0);
        // this.loadThumb(this.timestamp, this.percentage);
        this.img = await this._img();
      }
    });

    if (this.loop) {
      this.timestamp = this.startTime;
      this.timeSubject.next(this.time);
      this.percentage = 0;
      this.loopMask = false;
      this.loopInterval = setInterval(_ => {
        if (this.loopMask) {
          return;
        }
        this.timestamp += 2000;
        if (this.timestamp > this.endTime) {
          this.timestamp = this.startTime;
          this.timeSubject.next(this.time);
        }
        this.percentage = (this.timestamp - this.startTime) / (this.endTime - this.startTime);
        this.loadThumb(this.timestamp, this.percentage);
      }, LOOP_FREQUENCY);
    }
    this.multiPlaybackEffect.setDefault$.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.reset();
      });
    if (this.cameraIdx || this.cameraIdx === 0) {
      this.selectedCameras$.subscribe(async (cameras: EdgeCamera.CameraItem[]) => {
        this.selectedCameras = cameras;
        if (this.swap) {
          this.swap = false;
          return;
        } else {
          this.initMultiple();
        }
      });
      this.multiPlaybackEffect.swapCameras$.pipe(untilDestroyed(this))
        .subscribe(async () => {
          this.swap = true;
        });
    } else {
      this.baseUrl = `${environment.thumbnailsUrl}/thumbnails/${this.edgeId}/${this.cameraId}`;
    }
    this.displayTime = this.startTime;
    this.setImage();
    this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId: this.edgeId, cameraId: this.cameraId, base: this.getBaseInLocale(new Date(this.startTime)) }),
      )
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.setImage();
      });


  }

  reloadImage() {
    setTimeout(() => (this._reloadImage = false));
    setTimeout(() => (this._reloadImage = true));
  }

  convertTsToLocation(ts) {
    return this.cameraThumbnailsService.convertTsToZone(ts, this.timezone!, moment.tz.guess());
  }

  getThumbnailTimestamp(percentage: number) {
    const index = this.getThumbnailIndex(percentage);
    const ts = this.getTsFromFilename(this.thumbnailsUris[index]);
    if (!!ts) {
      // return this.cameraThumbnailsService.convertTsToZone(+ts, this.timezone!, moment.tz.guess());
      return +ts;
    }
    const idx = Math.floor(((this.endTime - this.startTime) / this.duration) * percentage);
    return this.getTimestampForIdx(idx);
  }

  reset() {
    this.loopMask = false;
    this.offline = false;
    if (this.first) {
      if (!!this.defaultThumbnail) {
        const tsRaw = +this.defaultThumbnail.split('-')[1];
        const ts = this.convertTsToLocation(tsRaw);
        this.timestamp = ts;
        this.timeSubject.next(this.time);
        this.percentage = (ts - this.startTime) / (this.endTime - this.startTime);
      } else {
        this.timestamp = this.getThumbnailTimestamp(0);
        this.timeSubject.next(this.time);
        this.percentage = 0;
      }
      // this.img = this.buildUrl(this.first);

    }
  }

  getThumbnailExists(percentage: number) {
    const index = this.getThumbnailIndex(percentage);
    return !!this.thumbnailsUris[index];
  }

  getThumbnailIndex(percentage: number) {
    const calc = this.offsetResInDurations
      ? Math.floor((this.thumbnailsUris?.length / this.offsetResInDurations) * percentage) * this.offsetResInDurations
      : Math.floor(this.thumbnailsUris?.length * percentage);
    return calc;
  }

  getThumbnailIndexLoop(percentage: number) {
    const calc = Math.floor(this.thumbnailsUris?.length * percentage);
    return calc;
  }

  buildUrl(filename: string) {
    return `${this.baseUrl}/thumbnail-${filename}.jpg`;
  }

  async loadThumb(timeStamp: number, percentage: number) {
    if (!this.events?.length || !this.thumbnailsUris?.length || percentage === undefined) {
      return;
    }
    percentage = Math.max(Math.min(percentage, 1), 0);
    const index = this.loop ? this.getThumbnailIndexLoop(percentage) : this.getThumbnailIndex(percentage);
    if (this.getThumbnailExists(percentage)) {
      this.img = await this._img();//this.buildUrl(this.thumbnailsUris[index]);
      this.offline = false;
    } else {
      this.offline = true;
    }
  }

  async hasThumb() {
    const { noThumbnail } = await this.noThumbnail(true);
    const hasBit = await this.getBitByTimestamp();
    return !noThumbnail || hasBit;
  }

  public async getBitByTimestamp() {
    return await this.mediaCacheService.getBitByTimestamp(this.edgeId, this.cameraId, this.timestamp);
  }

  async initThumbnails(swap = false, force = false) {
    this.error = false;
    const thumbsData: CameraThumbnailsData = {
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      timezone: this.timezone,
      offsetResInDurations: 600,
    };
    if (force) {
      delete this.events;
      delete this.first;
    }
    if (!this.events || this.events?.length === 0) {
      this.loading = true;
      this.error = false;
      try {
        await this.getThumbnails(thumbsData, this.startTime, this.endTime);
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
      const base = this.getBaseInLocale(new Date(this.startTime));
      const baseEnd = this.getBaseInLocale(new Date(this.endTime));
      if (!!this.eventsRaw[base] || !!this.eventsRaw[baseEnd]) {
        if (base !== baseEnd && baseEnd !== this.endTime) {
          const indexStart = this.cameraThumbnailsService.getEventLocation(this.startTime, base);
          const indexEnd = this.cameraThumbnailsService.getEventLocation(this.endTime, baseEnd);

          // We need to combine 2 days to one array
          let first: number[] = [];
          let second: number[] = [];
          if (!!this.eventsRaw[base]) {
            first = this.eventsRaw[base].slice(indexStart, this.eventsRaw[base].length);
          } else {
            first = new Array(43200).fill(0)
              .slice(indexStart, 43200);
          }
          if (!!this.eventsRaw[baseEnd]) {
            second = this.eventsRaw[baseEnd].slice(0, indexEnd);
          } else {
            second = new Array(43200).fill(0)
              .slice(0, indexEnd);
          }

          this.events = first.concat(second);
        } else {
          if (!!this.eventsRaw[base]) {
            const indexStart = this.cameraThumbnailsService.getEventLocation(this.startTime, base);
            const indexEnd = this.cameraThumbnailsService.getEventLocation(this.endTime, base);
            this.events = this.eventsRaw[base].slice(indexStart, indexEnd);
          }
        }
      } else {
        if (base !== baseEnd && baseEnd !== this.endTime) {
          this.events = [];
        }
      }

    }
    if (!swap && (this.cameraIdx || this.cameraIdx === 0)) {
      this.store$.dispatch(MultiPlaybackActions.setCameraEvents({ idx: this.cameraIdx, events: this.events }));
    }
    if (swap) {
      this.selectCameraEvents$.pipe(take(1))
        .subscribe(async (events: number[][]) => {
          delete this.defaultThumbnail;
          delete this.first;
          this.events = events[this.cameraIdx];
          this.buildUris();
          this.loading = false;
          this.reloadImage();
          this.img = await this._img();
        });
    } else {
      this.buildUris();
      this.loading = false;
      if (force) {
        this.loadThumb(this.timestamp, this.percentage);
      } else {
        this.img = await this._img(); //this.buildUrl(this.first);
      }
    }
    this.thumbInit = true;

  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date, this.timezone);
  }

  async getThumbnails(thumbsData: CameraThumbnailsData, startTime: number, endTime: number) {
    //todo add flag
    this.eventsRaw = await this.cameraThumbnailsService.getThumbnails(thumbsData, startTime, endTime, this.useSharedToken);
  }

  getTsFromFilename(filename: string) {
    return filename?.split('-')[0];
  }


  buildFileName(ts: number, replica: number = 0) {
    return `${ts}-${replica}-0`;
  }

  buildUris() {
    this.thumbnailsUris = [];
    for(let idx in this.events) {
      if (this.events[idx] === 0) {
        this.thumbnailsUris.push('');
        continue;
      }

      const ts = this.getTimestampForIdx(+idx);

      if (!this.first) {
        if (!!this.defaultThumbnail) {
          const ts = +this.defaultThumbnail.split('-')[1];
          const replica = +this.defaultThumbnail.split('-')[2];
          this.first = this.buildFileName(ts, replica);
        } else {
          this.first = this.buildFileName(ts);
        }
        this.currentTs = +this.getTsFromFilename(this.first);
      }

      if (this.events[idx] === 1) {
        this.thumbnailsUris.push(this.buildFileName(ts));
        continue;
      }

      for(let i = 0; i < this.events[idx]; i++) {
        this.thumbnailsUris.push(this.buildFileName(ts, i));
      }
    }
  }

  getTimestampForIdx(index) {
    return this.base + index * this.duration;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // if (changes['timestamp'] || changes['percentage']) {
    //   this.loadThumb(this.timestamp, this.percentage);
    // }

    if (changes['cameraIdx']) {
      this.initMultiple(true);
    }
  }

  public deleteCamera() {
    this.delete.emit();
  }

  public imgStateChange(event: StateChange) {
    switch (event.reason) {
      case 'start-loading':
        // The image is in the viewport so the image will start loading
        this.imageLoader = true;
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.imageLoader = false;
        break;
      case 'loading-failed':
        this.imageLoader = false;
        break;
    }
  }

  public async _img() {
    const { noThumbnail, next } = await this.noThumbnail();
    this.offline = !(await this.hasThumb());
    if (noThumbnail) {
      if (this.mediaCacheService.isTsAlignedTo20Seconds(this.timestamp)) {
        // this.offline = true;
        if (next) {
          let filename = this.buildFileName(this.normalizeTimestamp(next, 20000, true), 0);
          this.displayTime = this.normalizeTimestamp(this.timestamp, 20000, true);
          const url = this.buildUrl(filename);
          console.log(url);
          return url;
        }
        return '';
      } else {
        // this.offline = false;
        this.displayTime = this.normalizeTimestamp(this.timestamp, 20000, true);
        return this.img;
      }
    }
    this.offline = false;
    const { isAlert, replicas } = await this.isAlert();
    let replica;
    if (isAlert && replicas?.length) {
      // compute the replica according to offset between the 2 seconds and the number of replicas
      const offset = this.timestamp - this.normalizeTimestamp(this.timestamp, 2000);
      replica = Math.max(Math.floor(offset / 2000 * replicas.length), 0);
    }
    let filename = this.buildFileName(this.normalizeTimestamp(this.timestamp, 2000), 0);
    if (isAlert && replicas?.length) {
      filename = replicas[replica];
    }
    this.displayTime = this.normalizeTimestamp(this.timestamp, 2000);
    const url = this.buildUrl(filename);
    return url;
  }

  normalizeTimestamp(timestamp: number, freq = 2000, ceil?: boolean) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp, freq, ceil);
  }

  public async noThumbnail(align20 = false) {
    const timestamp = align20 ? this.cameraThumbnailsService.normalizeTimestamp(this.timestamp) : this.timestamp;
    return await this.mediaCacheService.noThumbnail(this.edgeId, this.cameraId, timestamp);
  }

  public async isAlert() {
    return await this.mediaCacheService.isAlert(this.edgeId, this.cameraId, this.timestamp);
  }

  async setImage() {
    if (!this.timestamp) {
      this.timestamp = this.startTime;
    }
    const base = this.cameraThumbnailsService.getBaseInLocale(new Date(this.timestamp));
    return this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId: this.edgeId, cameraId: this.cameraId, base }),
      )
      .pipe(filter(thumbs => !!thumbs), take(1), timeout(3000),
        catchError(err => {
          this.offline = true;
          return of(null);
        }),
      )
      .subscribe(async _ => {
        this.img = await this._img();
      });
  }

}
