import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, map, take } from 'rxjs';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { SublocationRowComponent } from '../sublocation-row/sublocation-row.component';
import { SelectedCamera } from '@models/alert-events.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { CameraSelectorModalModels } from '../../models/models';
import { Edge } from '../../../../edge/edge.model';
import { HomeModel } from '@models/home.model';
import { HomeSelectors } from '@states/home/home.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { CameraRowComponent } from '../camera-row/camera-row.component';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { LocationModel } from '../../../../locations/location.model';
import { PipesModule } from '../../../../pipes/pipes.module';

@UntilDestroy()
@Component({
  selector: 'camera-selector-location-row',
  standalone: true,
  imports: [
    MatCheckbox,
    NgIf,
    AsyncPipe,
    SublocationRowComponent,
    NgForOf,
    JsonPipe,
    CameraRowComponent,
    PipesModule,
  ],
  templateUrl: './location-row.component.html',
  styleUrls: ['./location-row.component.scss', './../../styles/index.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationRowComponent implements OnInit, OnChanges {

  @Input() location: CameraSelectorModalModels.Location;
  @Input() index: number;
  @Input() selectedCameras: Dictionary<SelectedCamera>;
  @Input() isCollapsed: boolean = false;
  @Input() isMulti: boolean = false;
  @Input() disabledCameras: Dictionary<string> = {};

  @Output() onCameraSelected: EventEmitter<SelectedCamera> = new EventEmitter<SelectedCamera>();
  @Output() onSublocationSelected: EventEmitter<{ locationId: string; sublocationId: string, checked: boolean }> = new EventEmitter<{ locationId: string; sublocationId: string, checked: boolean }>();
  @Output() onLocationSelected: EventEmitter<{ locationId: string; checked: boolean }> = new EventEmitter<{ locationId: string; checked: boolean }>();

  @Input() filteredCameras: LocationModel.LocationCameraItem[];

  @Input() isQuery: boolean;

  public collapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isCollapsed);

  public subLocations: HomeModel.Location[];
  public _cameras: HomeModel.Camera[];

  constructor(private store$: Store) {
  }

  public ngOnInit() {
    this.collapsed$.next(this.index !== 0);
    this.store$.select(HomeSelectors.selectSubLocations(this.location?._id))
      .pipe(untilDestroyed(this))
      .subscribe((subLocations) => {
        this.subLocations = subLocations;
      });
    this.store$.select(HomeSelectors.selectSubLocationCamerasById(this.location?._id))
      .pipe(take(1))
      .subscribe((cameras) => {
        this._cameras = cameras;
      });
  }

  public get cameras() {
    if (this.filteredCameras) {
      return this._cameras
        .filter(camera => this.filteredCameras.some(filteredCamera => filteredCamera?.edgeOnly?.cameraId === camera.cameraId));
    }
    return this._cameras;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['isCollapsed']) {
      this.collapsed$.next(changes['isCollapsed'].currentValue);
    }
  }

  public toggleLocation() {
    const currentValue = this.collapsed$.getValue();
    this.collapsed$.next(!currentValue);
  }

  public get edges() {
    return Object.values(this.location.edges ?? {});
  }

  public selectCamera(camera: SelectedCamera) {
    this.onCameraSelected.emit(camera);
  }

  public selectSublocation(value: { sublocationId: string, checked: boolean }) {
    this.onSublocationSelected.emit({ ...value, locationId: this.location._id });
  }

  public get selected() {
    const selectedCameras = Object.keys(this.selectedCameras);
    let locationCameras = Object.values(this.location.locationCameras);
    return locationCameras
      .every(camera => selectedCameras.includes(camera.edgeOnly.cameraId));
  }

  public selectLocation(ev: MatCheckboxChange) {
    this.onLocationSelected.emit({ locationId: this.location._id, checked: ev.checked });
  }

  public trackBy(index: number, sublocation: HomeModel.Location) {
    return sublocation._id;
  }

  public trackByCamera(index: number, camera: HomeModel.Camera) {
    return camera.cameraId;
  }

  getCameraById(cameraId: string) {
    return this.store$.pipe(select(CameraSelectors.selectCameraById(cameraId)))
      .pipe(
        map((res) => {
          return res as LocationModel.LocationCameraItem;
        }),
      );
  }
}
